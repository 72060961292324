import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import "./style.scss";


const Marquee = observer(() => {
  const { marqueeStore } = useStore();
  const { textString, speed, pauseOnHover } = marqueeStore;
  const [isPaused, setIsPaused] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    const scrollThreshold = 50; 
    let animationFrame: number;
    let start: number;
    const animate = (timestamp: number) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;

      if (elapsed > speed && textRef.current) {
        textRef.current.scrollLeft += 1;
        start = timestamp; 

        if (textRef.current.scrollLeft >= textRef.current.scrollWidth - textRef.current.offsetWidth - scrollThreshold) {
          textRef.current.scrollLeft = 0;
        }
      }

      if (!isPaused) {
        animationFrame = requestAnimationFrame(animate);
      }
    };
    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [isPaused]);

  const handleMouseEnter = () => {
    if (pauseOnHover) {
      setIsPaused(true);
    }
  };

  const handleMouseLeave = () => {
    if (pauseOnHover) {
      setIsPaused(false);
    }
  };

  return (
    <div
      id='marquee'
      className='marquee'
      ref={textRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <span 
        className='marquee-text-line'
        style={{ paddingRight: '100%' }}>{textString}</span>
      <span
        className='marquee-text-line'
        style={{ paddingRight: '100%' }}>{textString}</span>
    </div>
  );
});

export default Marquee;
