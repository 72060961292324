import { IconButton, Tooltip } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import GameCard from "components/GameCard";
import { observer } from "mobx-react";
import { GamesTableModel } from "models/GamesTableModel";
import { SearchModel } from "models/SearchModel";
import React, { useState } from 'react';
import { FaInfoCircle, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import Actions from '../../assets/imgs/actions.png';
import Clock from '../../assets/imgs/clock.png';
import Player from '../../assets/imgs/player.png';
import Size from '../../assets/imgs/size.png';
import Vlx from '../../assets/imgs/vlx.png';
import Winner from '../../assets/imgs/winner.png';

import "./style.scss";

interface Props {
  gamesModel: GamesTableModel;
  searchModel?: SearchModel;
  preview?: boolean;
}

export const GamesTable: React.FC<Props> = observer(({ gamesModel, searchModel, preview }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const renderSortIcon = (key: string) => {
    if (gamesModel.sortKey !== key) return <FaSort className="sort-icon" />;
    return gamesModel.sortDirection === 'asc' ? <FaSortUp className="sort-icon" /> : <FaSortDown className="sort-icon" />;
  };

  const filteredGames = searchModel 
    ? searchModel.filterGames(gamesModel.sortedGames)
    : gamesModel.sortedGames;
  const indexOfLastGame = currentPage * itemsPerPage;
  const indexOfFirstGame = indexOfLastGame - itemsPerPage;
  const currentGames = filteredGames.slice(indexOfFirstGame, indexOfLastGame);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className='games-table'>
      <div className='games-list'>
        {currentGames.length > 0 ? (
          <div>
            <div className='list-header'>
              <div className="room" onClick={() => gamesModel.setSortKey('room')}>
                # {renderSortIcon('room')}
              </div>
              <div className="players"><img src={Player} alt="player" width={24}/>Players</div>
              <div className="bet" onClick={() => gamesModel.setSortKey('betAmount')}>
                <img src={Vlx} alt="vlx" width={20}/> Bet {renderSortIcon('betAmount')}
              </div>
              <div className="time" onClick={() => gamesModel.setSortKey('timeLimitMinutes')}>
                <img src={Clock} alt="clock" width={20}/> Time Limit {renderSortIcon('timeLimitMinutes')}
              </div>
              <div className="gameSize" onClick={() => gamesModel.setSortKey('gameSize')}>
                <img src={Size} alt="size" width={20}/> Game size
                <Tooltip title="Size of game">
                  <IconButton size="small" style={{ filter: 'none' }} className="info">
                    <FaInfoCircle fontSize="small" />
                  </IconButton>
                </Tooltip>
                {renderSortIcon('gameSize')}
              </div>
              <div className="winner" onClick={() => gamesModel.setSortKey('winnerShort')}>
                <img src={Winner} alt="winner" width={24}/> Winner {renderSortIcon('winnerShort')}
              </div>
              <div className=""><img src={Actions} alt="actions" width={24}/>Actions</div>
            </div>
            {currentGames.map((game) => {
              const id = game.id;
              return <GameCard key={id} id={id} game={game} preview={preview} />;
            })}
          </div>
        ) : (
          <p className='container empty-state'>No games found...</p>
        )}
      </div>
      <Pagination
        count={Math.ceil(filteredGames.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        className="pagination-block"
      />
    </div>
  );
});
