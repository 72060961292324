import { useStore } from "hooks/useStore";
import { autorun } from "mobx";
import { useEffect } from "react";
import { useAccount } from "wagmi";

const ConnectionListener = () => {
  const { isConnected } = useAccount();
  const store = useStore();

  useEffect(() => {
    const dispose = autorun(() => {
      store.setConnectionStatus(isConnected);
    });

    // Cleanup the autorun when component unmounts
    return () => dispose();
  }, [isConnected]);

  return null;
};

export default ConnectionListener;