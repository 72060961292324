import { readContract } from '@wagmi/core';
import { config, gomokuContract } from "App/config";
import { action, computed, makeObservable, observable } from "mobx";
import { Game } from "models/Game";
import { emptyAddress, GameRaw, GameStatus } from "types";
import { abi } from "web3/contract/abi";
import { RootStore } from "./RootStore";


export class GamesStore {
  @observable isLoading = true;
  @observable _gamesList: GameRaw[] = [];
  @observable currentGameId: string | null = null;
  @observable fetchInterval: number | null = null;
  @observable hoveredGameId: string | null = null;

  constructor(private appStore: RootStore) {
    makeObservable(this);
    this.startPolling();
  }

  @action.bound
  startPolling() {
    if (!this.fetchInterval) {
      this.fetchInterval = setInterval(() => {
        this.fetchAllGames();
      }, 1500);
    }
  }

  @action.bound
  stopPolling() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
      this.fetchInterval = null;
    }
  }

  @computed
  get allGamesList() {
    return this._gamesList
      .map(_ => new Game(
        this.appStore,
        {..._}
      ))
      .reverse();
  }

  @computed
  get gamesList() {
    return this._gamesList
      .filter(_ => _.status !== GameStatus.CANCELED)
      .map(_ => new Game(
        this.appStore,
        {..._}
      ))
      .reverse().sort((a, b) => {
        if (a.player2 !== emptyAddress && b.player2 === emptyAddress) {
          return 1;
        }
        if (a.player2 === emptyAddress && b.player2 !== emptyAddress) {
          return -1;
        }
        return 0;
      });
  }

  @computed
  get myGamesList() {
    return this.gamesList.filter(game => game.isMyGame);
  }

  @computed
  get hoveredGame() {
    return this.gamesList.find(game => game.id === this.hoveredGameId);
  }

  @action.bound
  async fetchAllGames() {
    const result = await readContract(config, {
      abi,
      address: gomokuContract,
      functionName: 'listAllGames',
    });
    console.log('resultresult',{ gomokuContract, result })
    this._gamesList = result as GameRaw[];
    if (this.isLoading) {
      this.isLoading = false;
    }
  }


  getPlayersGames(address: string) {
    if (!address) return [];
    return this.gamesList.filter(game => game.player1 === address || game.player2 === address);
  }

  @action.bound
  handleMouseEnter(gameId: string) {
    return (event: React.MouseEvent<HTMLDivElement>) => {
      // Do whatever you need with gameId
      console.log("Hovered over game with ID:", gameId);
      this.hoveredGameId = gameId;

    };
  }

  @action.bound
  handleMouseLeave() {
    this.hoveredGameId = null;
  }
}