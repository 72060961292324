type Color = string;
type IconName = string;

export type SvgIconProps = {
  height?: number;
  width?: number;
  secondaryColor?: Color;
  className?: string;
  name: IconName;
  stroke?: string;
} & (
    | { height: number; width: number; size?: number; fill?: string; background?: string; }
    | { height?: number; width?: number; size?: number; fill?: string; background?: string; }
  );

export const icons = (props: SvgIconProps) => (
  {

    lock: (
      //@ts-ignore
      // <svg width={props.width} height={props.height} id="Layer_1" style={{enableBackground:'new 0 0 30 30'}} version="1.1" viewBox="0 0 30 30" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      //   <path d="M24,27H6c-1.105,0-2-0.895-2-2V13c0-1.105,0.895-2,2-2h18c1.105,0,2,0.895,2,2v12C26,26.105,25.105,27,24,27z"/>
      //   //@ts-ignore
      //   <path d="M9,12  V9c0-3.314,2.686-6,6-6h0c3.314,0,6,2.686,6,6v3" style={{fill:"none", stroke: "#000000", strokeWidth:2, strokeLinecap:'round', strokeLinejoin: 'round', strokeMiterlimit:10 }}/>
      // </svg>
      <svg width={props.width} height={props.height} viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_638_717)">
        <g filter="url(#filter0_d_638_717)">
        <path d="M15.3933 7.73808H14.295V6.63977C14.295 5.18331 13.7165 3.78651 12.6866 2.75664C11.6567 1.72677 10.2599 1.14819 8.80346 1.14819C7.347 1.14819 5.9502 1.72677 4.92033 2.75664C3.89046 3.78651 3.31188 5.18331 3.31188 6.63977V7.73808H2.21357C1.92228 7.73808 1.64292 7.8538 1.43695 8.05977C1.23097 8.26574 1.11526 8.5451 1.11526 8.8364V17.3593C1.11303 17.9729 1.28263 18.5748 1.60485 19.097C1.92707 19.6191 2.38904 20.0406 2.93846 20.3138L8.30922 22.9937C8.46189 23.073 8.63141 23.1144 8.80346 23.1144C8.97551 23.1144 9.14503 23.073 9.2977 22.9937L14.6685 20.3138C15.2179 20.0406 15.6798 19.6191 16.0021 19.097C16.3243 18.5748 16.4939 17.9729 16.4917 17.3593V8.8364C16.4917 8.5451 16.3759 8.26574 16.17 8.05977C15.964 7.8538 15.6846 7.73808 15.3933 7.73808ZM9.90177 15.4263C9.90177 15.7176 9.78606 15.9969 9.58008 16.2029C9.37411 16.4089 9.09475 16.5246 8.80346 16.5246C8.51217 16.5246 8.23281 16.4089 8.02683 16.2029C7.82086 15.9969 7.70514 15.7176 7.70514 15.4263V13.2297C7.70514 12.9384 7.82086 12.659 8.02683 12.453C8.23281 12.2471 8.51217 12.1313 8.80346 12.1313C9.09475 12.1313 9.37411 12.2471 9.58008 12.453C9.78606 12.659 9.90177 12.9384 9.90177 13.2297V15.4263ZM12.0984 7.73808H5.50851V6.63977C5.50851 5.76589 5.85566 4.92781 6.47358 4.30989C7.0915 3.69197 7.92958 3.34482 8.80346 3.34482C9.67733 3.34482 10.5154 3.69197 11.1333 4.30989C11.7513 4.92781 12.0984 5.76589 12.0984 6.63977V7.73808Z" fill="url(#paint0_linear_638_717)"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_d_638_717" x="1.11523" y="1.14819" width="15.3765" height="23.529" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.56272"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.686275 0 0 0 0 0.0745098 0 0 0 0 0.619608 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_638_717"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_638_717" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_638_717" x1="7.04958" y1="5.6847" x2="4.07614" y2="19.7598" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#B39CB3"/>
        </linearGradient>
        <clipPath id="clip0_638_717">
        <rect width="16.5239" height="24.0974" fill="white" transform="translate(0.476074 0.689453)"/>
        </clipPath>
        </defs>
        </svg>

    ),
    
    downloadArrow: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
        <path
          d="M5.47 9.07C5.3752 9.00057 5.29583 8.91223 5.23689 8.81057C5.17795 8.70891 5.14074 8.59614 5.12759 8.47937C5.11444 8.3626 5.12565 8.24437 5.1605 8.13215C5.19535 8.01993 5.25309 7.91615 5.33008 7.82737C5.40707 7.7386 5.50162 7.66675 5.60778 7.61636C5.71394 7.56598 5.82939 7.53815 5.94685 7.53464C6.0643 7.53112 6.18121 7.552 6.29019 7.59596C6.39917 7.63991 6.49785 7.70598 6.58 7.79L8 9V1C8 0.734784 8.10536 0.48043 8.29289 0.292893C8.48043 0.105357 8.73478 0 9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1V9L11.42 7.77C11.5022 7.68598 11.6008 7.61991 11.7098 7.57596C11.8188 7.532 11.9357 7.51112 12.0532 7.51464C12.1706 7.51815 12.2861 7.54598 12.3922 7.59636C12.4984 7.64675 12.5929 7.7186 12.6699 7.80737C12.7469 7.89615 12.8046 7.99993 12.8395 8.11215C12.8744 8.22437 12.8856 8.3426 12.8724 8.45936C12.8593 8.57613 12.822 8.68891 12.7631 8.79057C12.7042 8.89223 12.6248 8.98057 12.53 9.05L9.53 11.63C9.49897 11.6536 9.46544 11.6737 9.43 11.69C9.38338 11.7226 9.33302 11.7494 9.28 11.77H9.13H8.97H8.81H8.66C8.60698 11.7494 8.55662 11.7226 8.51 11.69C8.47456 11.6737 8.44102 11.6536 8.41 11.63L5.47 9.07ZM17 10C16.7348 10 16.4804 10.1054 16.2929 10.2929C16.1054 10.4804 16 10.7348 16 11V14C16 14.2652 15.8946 14.5196 15.7071 14.7071C15.5196 14.8946 15.2652 15 15 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V11C2 10.7348 1.89464 10.4804 1.70711 10.2929C1.51957 10.1054 1.26522 10 1 10C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11V14C0 14.7956 0.316071 15.5587 0.87868 16.1213C1.44129 16.6839 2.20435 17 3 17H15C15.7956 17 16.5587 16.6839 17.1213 16.1213C17.6839 15.5587 18 14.7956 18 14V11C18 10.7348 17.8946 10.4804 17.7071 10.2929C17.5196 10.1054 17.2652 10 17 10Z"
          fill="#0560FD"/>
      </svg>
    ),

    trophy: (
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        // xmlns:xlink="http://www.w3.org/1999/xlink" 
        version="1.1" width={props.width || "512px"} height={props.height || "512px"}
        viewBox={"0 0 512 512"}
        style={{
          filter: 'drop-shadow(2px 4px 6px black)',
          "shapeRendering":"geometricPrecision", "textRendering": "geometricPrecision", "imageRendering":"auto", "fillRule": "evenodd", "clipRule": "evenodd"}}>
        <g><path style={{"opacity":0.956}} fill="#f7bb14" d="M 46.5,129.5 C 43.5667,145.529 42.9,161.529 44.5,177.5C 44.7795,190.296 46.1128,202.963 48.5,215.5C 50.6424,231.406 54.6424,246.74 60.5,261.5C 64.4394,273.372 69.4394,284.705 75.5,295.5C 80.3517,307.536 86.5184,318.869 94,329.5C 95.617,331.763 97.4503,333.763 99.5,335.5C 106.741,346.077 115.074,355.743 124.5,364.5C 134.305,377.23 145.972,387.897 159.5,396.5C 164.525,400.345 169.858,403.678 175.5,406.5C 174.464,412.285 172.464,417.618 169.5,422.5C 149.823,411.146 132.156,397.146 116.5,380.5C 104.724,368.399 93.7243,355.732 83.5,342.5C 76.0796,331.999 69.4129,320.999 63.5,309.5C 56.2633,294.365 49.5967,279.032 43.5,263.5C 39.2326,251.098 35.8993,238.432 33.5,225.5C 31.2592,208.513 29.2592,191.513 27.5,174.5C 27.0029,140.81 31.5029,107.81 41,75.5C 45.5135,69.253 50.5135,68.9196 56,74.5C 56.1667,76 56.3333,77.5 56.5,79C 52.3329,95.6689 48.9995,112.502 46.5,129.5 Z"/></g>
        <g><path style={{"opacity":0.956}} fill="#f7bb14" d="M 483.5,174.5 C 481.83,191.545 479.83,208.545 477.5,225.5C 475.101,238.432 471.767,251.098 467.5,263.5C 461.403,279.032 454.737,294.365 447.5,309.5C 441.587,320.999 434.92,331.999 427.5,342.5C 417.276,355.732 406.276,368.399 394.5,380.5C 379.036,396.888 361.703,410.888 342.5,422.5C 339.531,417.601 337.531,412.267 336.5,406.5C 341.672,403.415 346.672,400.081 351.5,396.5C 365.028,387.897 376.695,377.23 386.5,364.5C 395.926,355.743 404.259,346.077 411.5,335.5C 417.046,329.432 421.879,322.765 426,315.5C 429.986,309.195 433.153,302.528 435.5,295.5C 441.561,284.705 446.561,273.372 450.5,261.5C 456.358,246.74 460.358,231.406 462.5,215.5C 464.887,202.963 466.22,190.296 466.5,177.5C 468.1,161.529 467.433,145.529 464.5,129.5C 462,112.502 458.667,95.6689 454.5,79C 455.039,72.2181 458.706,69.5514 465.5,71C 467.667,71.8333 469.167,73.3333 470,75.5C 479.497,107.81 483.997,140.81 483.5,174.5 Z"/></g>
        <g><path style={{"opacity":0.934}} fill="#fe901c" d="M 44.5,177.5 C 42.9,161.529 43.5667,145.529 46.5,129.5C 55.3325,121.503 64.3325,113.669 73.5,106C 76.1233,103.805 78.1233,104.305 79.5,107.5C 72.5369,133.426 60.8702,156.76 44.5,177.5 Z"/></g>
        <g><path style={{"opacity":0.934}} fill="#fe901c" d="M 464.5,129.5 C 467.433,145.529 468.1,161.529 466.5,177.5C 450.13,156.76 438.463,133.426 431.5,107.5C 432.877,104.305 434.877,103.805 437.5,106C 446.668,113.669 455.668,121.503 464.5,129.5 Z"/></g>
        <g><path style={{"opacity":0.986}} fill="#feb125" d="M 305.5,123.5 C 305.5,132.833 305.5,142.167 305.5,151.5C 302.167,151.5 298.833,151.5 295.5,151.5C 256.5,151.5 217.5,151.5 178.5,151.5C 174.993,152.006 171.826,151.34 169,149.5C 168.333,141.5 168.333,133.5 169,125.5C 169.5,125 170,124.5 170.5,124C 215.499,123.5 260.499,123.333 305.5,123.5 Z"/></g>
        <g><path style={{"opacity":1}} fill="#ff901d" d="M 305.5,123.5 C 317.171,123.333 328.838,123.5 340.5,124C 341,124.5 341.5,125 342,125.5C 342.667,133.5 342.667,141.5 342,149.5C 339.174,151.34 336.007,152.006 332.5,151.5C 323.5,151.5 314.5,151.5 305.5,151.5C 305.5,142.167 305.5,132.833 305.5,123.5 Z"/></g>
        <g><path style={{"opacity":0.941}} fill="#fe901c" d="M 27.5,174.5 C 29.2592,191.513 31.2592,208.513 33.5,225.5C 19.5894,215.05 10.0894,201.383 5,184.5C 2.69968,176.806 0.866349,169.14 -0.5,161.5C -0.5,154.167 -0.5,146.833 -0.5,139.5C 1.00978,138.272 2.50978,138.272 4,139.5C 10.9127,151.889 18.746,163.556 27.5,174.5 Z"/></g>
        <g><path style={{"opacity":0.941}} fill="#fe901c" d="M 511.5,139.5 C 511.5,146.833 511.5,154.167 511.5,161.5C 508.794,181.439 501.294,199.439 489,215.5C 485.59,219.416 481.757,222.749 477.5,225.5C 479.83,208.545 481.83,191.545 483.5,174.5C 492.254,163.556 500.087,151.889 507,139.5C 508.49,138.272 509.99,138.272 511.5,139.5 Z"/></g>
        <g><path style={{"opacity":0.995}} fill="#fdd02f" d="M 178.5,151.5 C 217.5,151.5 256.5,151.5 295.5,151.5C 295.667,173.503 295.5,195.503 295,217.5C 291.563,254.544 275.396,284.71 246.5,308C 243.3,310.099 239.967,311.933 236.5,313.5C 227.879,308.693 220.212,302.693 213.5,295.5C 209.07,289.64 204.403,283.974 199.5,278.5C 187.81,260.6 180.977,240.933 179,219.5C 178.5,205.837 178.333,192.171 178.5,178.5C 178.5,173.167 178.5,167.833 178.5,162.5C 178.5,158.833 178.5,155.167 178.5,151.5 Z"/></g>
        <g><path style={{"opacity":1}} fill="#f7bc14" d="M 295.5,151.5 C 298.833,151.5 302.167,151.5 305.5,151.5C 314.5,151.5 323.5,151.5 332.5,151.5C 332.5,155.167 332.5,158.833 332.5,162.5C 332.5,167.833 332.5,173.167 332.5,178.5C 332.667,192.171 332.5,205.837 332,219.5C 330.023,240.933 323.19,260.6 311.5,278.5C 306.863,284.142 302.197,289.809 297.5,295.5C 292.388,300.439 287.054,305.272 281.5,310C 278.46,312.22 275.127,313.72 271.5,314.5C 274.85,344.218 284.184,371.884 299.5,397.5C 297.585,397.216 295.919,397.549 294.5,398.5C 266.662,398.831 238.995,398.498 211.5,397.5C 228.513,397.833 245.513,397.499 262.5,396.5C 247.499,371.483 239.832,344.483 239.5,315.5C 238.5,314.833 237.5,314.167 236.5,313.5C 239.967,311.933 243.3,310.099 246.5,308C 275.396,284.71 291.563,254.544 295,217.5C 295.5,195.503 295.667,173.503 295.5,151.5 Z"/></g>
        <g><path style={{"opacity":0.943}} fill="#ffb125" d="M 178.5,162.5 C 178.5,167.833 178.5,173.167 178.5,178.5C 163.167,178.5 147.833,178.5 132.5,178.5C 128.438,209.137 136.771,235.637 157.5,258C 169.496,268.831 183.496,275.664 199.5,278.5C 204.403,283.974 209.07,289.64 213.5,295.5C 168.153,294.416 137.32,272.749 121,230.5C 119.191,224.263 117.857,217.93 117,211.5C 116.333,197.833 116.333,184.167 117,170.5C 118.167,166.667 120.667,164.167 124.5,163C 142.497,162.5 160.497,162.333 178.5,162.5 Z"/></g>
        <g><path style={{"opacity":0.943}} fill="#feb124" d="M 332.5,162.5 C 350.503,162.333 368.503,162.5 386.5,163C 390.333,164.167 392.833,166.667 394,170.5C 394.667,184.167 394.667,197.833 394,211.5C 387.329,253.174 363.829,280.341 323.5,293C 314.947,295.004 306.281,295.837 297.5,295.5C 302.197,289.809 306.863,284.142 311.5,278.5C 345.194,271.576 366.694,251.576 376,218.5C 378.138,205.259 378.971,191.925 378.5,178.5C 363.167,178.5 347.833,178.5 332.5,178.5C 332.5,173.167 332.5,167.833 332.5,162.5 Z"/></g>
        <g><path style={{"opacity":0.937}} fill="#fe901c" d="M 60.5,261.5 C 54.6424,246.74 50.6424,231.406 48.5,215.5C 54.4089,205.74 60.5756,196.073 67,186.5C 69.084,183.967 71.084,183.967 73,186.5C 74.4471,212.484 70.2804,237.484 60.5,261.5 Z"/></g>
        <g><path style={{"opacity":0.937}} fill="#fe901c" d="M 462.5,215.5 C 460.358,231.406 456.358,246.74 450.5,261.5C 439.653,236.383 436.32,210.716 440.5,184.5C 442.029,184.529 443.196,185.196 444,186.5C 450.424,196.073 456.591,205.74 462.5,215.5 Z"/></g>
        <g><path style={{"opacity":0.932}} fill="#fe901c" d="M 43.5,263.5 C 49.5967,279.032 56.2633,294.365 63.5,309.5C 44.5821,302.42 30.0821,290.086 20,272.5C 14.1359,262.435 9.63592,251.768 6.5,240.5C 7.09626,237.754 8.76293,236.92 11.5,238C 21.6502,247.23 32.3168,255.73 43.5,263.5 Z"/></g>
        <g><path style={{"opacity":0.932}} fill="#fe901c" d="M 447.5,309.5 C 454.737,294.365 461.403,279.032 467.5,263.5C 478.683,255.73 489.35,247.23 499.5,238C 502.237,236.92 503.904,237.754 504.5,240.5C 498.414,264.059 486.08,283.559 467.5,299C 461.248,303.292 454.582,306.792 447.5,309.5 Z"/></g>
        <g><path style={{"opacity":0.94}} fill="#fe901c" d="M 99.5,335.5 C 97.4503,333.763 95.617,331.763 94,329.5C 86.5184,318.869 80.3517,307.536 75.5,295.5C 78.3467,284.117 81.5134,272.784 85,261.5C 86.3117,259.342 87.9783,259.009 90,260.5C 99.1739,284.893 102.341,309.893 99.5,335.5 Z"/></g>
        <g><path style={{"opacity":0.94}} fill="#fe901c" d="M 435.5,295.5 C 433.153,302.528 429.986,309.195 426,315.5C 421.879,322.765 417.046,329.432 411.5,335.5C 408.659,309.893 411.826,284.893 421,260.5C 423.022,259.009 424.688,259.342 426,261.5C 429.487,272.784 432.653,284.117 435.5,295.5 Z"/></g>
        <g><path style={{"opacity":0.964}} fill="#fdd02f" d="M 239.5,315.5 C 239.832,344.483 247.499,371.483 262.5,396.5C 245.513,397.499 228.513,397.833 211.5,397.5C 226.609,372.175 235.942,344.842 239.5,315.5 Z"/></g>
        <g><path style={{"opacity":0.937}} fill="#fe901c" d="M 159.5,396.5 C 145.972,387.897 134.305,377.23 124.5,364.5C 123.541,351.846 123.374,339.179 124,326.5C 125.049,325.483 126.216,325.316 127.5,326C 143.981,346.8 154.648,370.3 159.5,396.5 Z"/></g>
        <g><path style={{"opacity":0.937}} fill="#fe901c" d="M 386.5,364.5 C 376.695,377.23 365.028,387.897 351.5,396.5C 356.352,370.3 367.019,346.8 383.5,326C 384.784,325.316 385.951,325.483 387,326.5C 387.626,339.179 387.459,351.846 386.5,364.5 Z"/></g>
        <g><path style={{"opacity":0.939}} fill="#fe901c" d="M 83.5,342.5 C 93.7243,355.732 104.724,368.399 116.5,380.5C 104.395,380.14 93.0613,376.973 82.5,371C 65.4569,360.658 51.7902,346.991 41.5,330C 42.3992,328.764 43.5659,327.93 45,327.5C 57.5813,333.193 70.4147,338.193 83.5,342.5 Z"/></g>
        <g><path style={{"opacity":0.938}} fill="#fe901c" d="M 394.5,380.5 C 406.276,368.399 417.276,355.732 427.5,342.5C 440.585,338.193 453.419,333.193 466,327.5C 467.434,327.93 468.601,328.764 469.5,330C 455.696,354.471 435.363,370.805 408.5,379C 403.898,380.122 399.232,380.622 394.5,380.5 Z"/></g>
        <g><path style={{"opacity":0.983}} fill="#feb024" d="M 211.5,397.5 C 238.995,398.498 266.662,398.831 294.5,398.5C 300.296,412.22 305.296,426.22 309.5,440.5C 261.832,440.667 214.166,440.5 166.5,440C 165.833,439 165.167,438 164.5,437C 166.067,432.133 167.733,427.3 169.5,422.5C 172.464,417.618 174.464,412.285 175.5,406.5C 176.355,403.431 177.689,400.597 179.5,398C 190.161,397.5 200.828,397.333 211.5,397.5 Z"/></g>
        <g><path style={{"opacity":1}} fill="#fe901d" d="M 299.5,397.5 C 310.505,397.333 321.505,397.5 332.5,398C 333.948,400.876 335.281,403.709 336.5,406.5C 337.531,412.267 339.531,417.601 342.5,422.5C 343.556,426.168 344.722,429.835 346,433.5C 346.808,435.579 346.641,437.579 345.5,439.5C 333.551,440.479 321.551,440.813 309.5,440.5C 305.296,426.22 300.296,412.22 294.5,398.5C 295.919,397.549 297.585,397.216 299.5,397.5 Z"/></g>
      </svg>
    )

  }
)
