import { formatTime } from 'helpers/game';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { useState } from 'react';

interface Props {
  player: 1 | 2; // Indicates which player's timer to render
  timeLeft: number;
}



export const GameTimer = observer(({ player, timeLeft }: Props) => {
  const store = useStore();
  const game = store.gameStore.currentGame;

  const [remainingTime, setRemainingTime] = useState(timeLeft);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  console.log('GameTimer', player, remainingTime, timeLeft);


  const startTimer = () => {
      const id = setInterval(() => {
          setRemainingTime((prev) => (prev > 0 ? prev - 1 : 0)); // Decrease time every second
      }, 1000); // Update every second

      setIntervalId(id);
  };

  // useEffect(() => {
  //     // Start or stop the timer based on the current player
      
  //     if (game?.alreadyStarted) {
  //       console.log('game?.alreadyStarted,', game?.alreadyStarted)
  //         if (game.currentPlayer === player && !game?.isFinished) {
  //             setRemainingTime(timeLeft);
  //             startTimer(); // Start timer when it's this player's turn
  //         } else {
  //             clearInterval(intervalId!); // Stop the timer if it's not this player's turn
  //         }
  //     }

  //     return () => {
  //         if (intervalId) {
  //             clearInterval(intervalId); // Clear interval on component unmount
  //         }
  //     };
  // }, [game?.currentPlayer, game?.isFinished,]); // Depend on currentPlayer to trigger effect

  return (
      <div>
          <span>{formatTime(timeLeft)}</span>
      </div>
  );
});
