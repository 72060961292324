import HexAvatar from "components/Avatar";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { copyToClipboard } from "services";
import './styles.scss';

interface AddressProps {
  value: string;
  addressToCopy?: string;
  address?: string;
}

export const Address = ({ value, addressToCopy, address }: AddressProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    if (addressToCopy) {
      copyToClipboard(addressToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  return (
    <>
      <span className="address-container" style={{ display: "flex", gap: '5px', alignItems: 'center' }}>
        {address && <HexAvatar address={address} width={20} height={20} />}
        {address ? (
          <Link className="address-value" to={`/member/${address}`}>{value}</Link>
        ): (
          <>
            <span 
              onClick={handleCopy} 
              className="address-value">
              {value}
            </span>
            {isCopied && (
              <FaCheck className="copied-icon" style={{ color: "#61e261", width: '11px' }} />
            )}
          </>
        )}
      </span>
    </>
  );
}
