import React from "react";
import './style.scss';
import NotFoundImg from '../../assets/imgs/404.png';
import NotFoundImgDark from '../../assets/imgs/404-dark.png';
import { Button } from '@material-ui/core';
import { useStore } from 'hooks/useStore';

const NotFound: React.FC = () => {
  const store = useStore();

  return (
    <div className="not_found">

      <img className='NotFoundImg' src={NotFoundImg} alt='404' />
      <img className='NotFoundImg dark' src={NotFoundImgDark} alt='404' />

      <h1>Ooops.. Page Not Found</h1>
      <span>Sorry, the page you are looking for does not exist.</span>
      <Button onClick={() => store.navigate('/')}>Go Home</Button>
    </div>
  );
};

export default NotFound;
