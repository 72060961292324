import { action, computed, makeObservable, observable } from "mobx";
import { Game } from "models/Game";

type SortKey = 'room' | 'betAmount' | 'timeLimitMinutes' | 'winnerShort' | 'gameSize';
type SortDirection = 'asc' | 'desc';

export class GamesTableModel {
  @observable games: Game[] = [];
  @observable sortKey: SortKey | null = null;
  @observable sortDirection: SortDirection = 'asc';

  constructor(games: Game[]) {
    makeObservable(this);
    this.setGames(games);
  }

  @action.bound
  setGames(games: Game[]) {
    this.games = games;
  }

  @action.bound
  setSortKey(key: SortKey) {
    if (this.sortKey === key) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortKey = key;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get sortedGames() {
    if (!this.sortKey) return this.games;

    return [...this.games].sort((a, b) => {
      let aValue: any;
      let bValue: any;

      switch (this.sortKey) {
        case 'room':
          aValue = parseInt(a.id);
          bValue = parseInt(b.id);
          break;
        case 'betAmount':
          aValue = a.betAmount || 0;
          bValue = b.betAmount || 0;
          break;
        case 'timeLimitMinutes':
          aValue = a.timeLimitMinutes || 0;
          bValue = b.timeLimitMinutes || 0;
          break;
        case 'winnerShort':
          aValue = a.winnerShort || '';
          bValue = b.winnerShort || '';
          break;
        case 'gameSize':
          aValue = a.gameLength || 0;
          bValue = b.gameLength || 0;
          break;
        default:
          return 0;
      }

      if (aValue === bValue) return 0;
      return this.sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
    });
  }
}
