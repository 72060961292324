import { Button, IconButton } from '@material-ui/core';
import HexAvatar from 'components/Avatar';
import { GamesTable } from 'components/GamesTable';
import { Loader } from 'components/Loader';
import NotFound from 'components/NotFound';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { GamesTableModel } from 'models/GamesTableModel';
import { useEffect } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import "./style.scss";

type RouteParams = {
  address: string;
};

export const Profile = observer(() => {
  const { address } = useParams<RouteParams>();
  const { membersStore, profileStore } = useStore();
  const store = useStore();

  useEffect(() => {
    if (address) {
      membersStore.currentMemberAddress = address;
    }
    return () => {
      membersStore.currentMemberAddress = undefined;
    }
  }, [address])
  if (profileStore.profileIsLoading) return <Loader show />;
  const member = membersStore.memberProfile;
  if (!member) return <NotFound />;
  const { nickname, currentGames, completedGames, stats } = member;

  const gamesTableModel = new GamesTableModel(currentGames);
  const completedGamesTM = new GamesTableModel(completedGames);

  return (
    <div className='main-container game-content profile'>
      <div className='body'>
      
        <section className='actions'>
          <div className="group-btns">
            <Button className='btn-back' onClick={() => store.navigate('/')}>⬅︎</Button>
          </div>
          <section className='details details-header'>
            <div className='section-details-avatar'>
              <HexAvatar address={address} width={65} height={65} />
            </div>
            <span className='section-details-info'>
              {!nickname && profileStore.showSetNicknameButton ? (
                <div className='section-details-info-nickname'>
                  <Button onClick={profileStore.openModal} className="btn-profile">
                  Click here to set nickname...
                  </Button>
                </div>

              ) : (
                <div className='section-details-info-nickname'>
                  <span>{nickname}</span>
                  {profileStore.showSetNicknameButton && (
                    <IconButton
                      onClick={profileStore.openModal} 
                      size="small" 
                      style={{ filter: 'none' }} 
                      className="info">
                      <FaPencilAlt fontSize="small" />
                    </IconButton>
                  )}
                </div>
              )}
              <div className='section-details-info-address'>{address}</div>
              <div className='profile-card-user-info'>

              </div>
            </span>
        </section>
        </section>

        <section className='games-list'>
          <div className='content'>
            <div className='title'><h2>Games in progress</h2></div>
              <section className='games-current'>
                {currentGames.length ? (
                  <GamesTable gamesModel={gamesTableModel} />
                ) : (
                  <span>No games in progress</span>
                )}
              </section>
          </div>
          <div className='content'>
            <div className='title'><h2>Completed Games</h2></div>
            <section className='games-completed'>
              {completedGames.length ? (
                <GamesTable gamesModel={completedGamesTM} preview />
              ) : (
                <span>No completed games</span>
              )}
              </section>
          </div>
        </section>
      </div>
    </div>
  );
});