
import { createConfig, http } from 'wagmi';
import { mainnet } from 'wagmi/chains';

type FetchResponse = {
  jsonrpc: string;
  result: string;
  id: string;
}

export const gomokuContract = '0xfb2145bbda0dafe1c2a5c9e39b9a05486d709ce0';
export const GameContract = '0x0e95319d323ef5875d5cd11484972aa0d07a36ec';
 
export const velas = {
  id: 106,
  name: 'Velas',
  network: 'velas',
  nativeCurrency: {
    decimals: 18,
    name: 'Velas',
    symbol: 'VLX',
  },
  rpcUrls: {
    default: {
      http: ['https://evmexplorer.velas.com/rpc'],
    },
  },
  iconUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/4747.png",
  // rpcUrls: {
  //   public: { http: ['https://api.testnet.velas.com/rpc'] },
  //   default: { http: ['https://api.testnet.velas.com/rpc'] },
  // },
  blockExplorers: {
    etherscan: { name: 'Velas Explorer t', url: 'https://evmexplorer.velas.com/' },
    default: { name: 'Velas Explorer t', url: 'https://evmexplorer.velas.com/' },
  },
  // contracts: {
  //   multicall3: {
  //     address: '0xca11bde05977b3631167028862be2a173976ca11',
  //     blockCreated: 11_907_934,
  //   },
  // },
};


//@ts-ignore
export const config = createConfig({
  chains: [
    // mainnet, 
    velas
  ],
  connectors: [
    // metaMask(),
  ],
  transports: {
    [mainnet.id]: http(),
    // [sepolia.id]: http(),
    [velas.id]: http(velas.rpcUrls.default.http[0]),
    // [velas.id]: custom({ 
    //   async request({ method, params }) { 
    //     const pars = {
    //       method,
    //       "jsonrpc": "2.0",
    //       params,
    //       "id": "ec3dsdd-ad8d-4166-be92-46cdskk65fb3"
    //     }
        
    //     const res = await fetch_(velas.rpcUrls.default.http[0], 
    //       {
    //         method: 'POST', // or 'GET', 'PUT', etc.
    //         headers: {
    //           'Content-Type': 'application/json',
    //           // Add other headers if needed
    //         },
    //         body: JSON.stringify(pars)
    //       }
    //     );
    //     console.log({resres: res})
    //     // const headers = {
    //     //   'Content-Type': 'application/json',
    //     //   'Access-Control-Allow-Origin': '*',
    //     //   'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    //     // }
    //     // const response = await fetch(velas.rpcUrls.default.http[0], {method, headers, body: JSON.stringify(pars)})
    //     // return response
    //     console.log({res})
    //     //@ts-ignore
    //     return res as FetchResponse;
    //   } 
    // }) 
  },
})