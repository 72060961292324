import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip
} from "@material-ui/core";
// import InfoIcon from "@material-ui/icons/Info";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FaInfoCircle } from 'react-icons/fa';
import Vlx from '../../../../assets/imgs/vlx.png';


import React from "react";

import { FormCol, FormRow } from "./styled";

import { gomokuContract } from "App/config";
import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import { abi } from "web3/contract/abi";
// import { Opponent as GameOpponent } from "stores/NewGame";
import CustomButton from "components/CustomButton";
import { ethers } from "ethers";
import { emptyAddress } from "models/Game";
import { gameTypeData } from "stores/NewGame";
import { BetType, Opponent as GameOpponent, GameSize, GameTypes } from "types";
import { Difficulty, Opponent, Player } from "utils/GameBoard";
import { useAccount, useBalance, useWriteContract } from "wagmi";
import CustomRadio from "./CustomRadio";
import GridImg from "./GridImg";
import './style.scss';

type DialogProps = {
  open: boolean;
  onClose: () => void;
};

const NewGameDialog = observer((props: DialogProps) => {
  const store = useStore();
  // const gameBoard: GameBoard = GameBoard.getInstance(GAME_LENGTH);
  const { data: hash, writeContract, writeContractAsync, ...rest } = useWriteContract();
  console.log('{rest,', { rest })
  const { newGame } = useStore();
  const {
    bet,
    betType,
    opponent,
    gameLength,
    opponentType,
    handleGameBetChange,
    handleGameBetTypeChange,
    handleGameLengthChange,
    handleOpponentChange,
    handleGameTypeChange,
    handleOpponentAddress,
    allRequiredFieldsAreSet,
    validateFormData,
    hasError,
    errorMessage
  } = newGame;
  const { open, onClose } = props;
  const [color, setColor] = React.useState(Player.BLACK);
  const [difficulty, setDifficulty] = React.useState(Difficulty.MEDIUM);
  console.log({ opponent })
  const { address } = useAccount();
  const { data: balanceData } = useBalance({ address });
  const availableBalance = ethers.formatEther(balanceData?.value || '0');

  const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleGameBetChange(event, availableBalance);
  };

  const onNewGame = async (
    playerColor: Player,
    difficulty: Difficulty
  ) => {
    const result = validateFormData();
    const opponentWasPredefined = !!newGame.expectedPlayer2 && newGame.expectedPlayer2 !== emptyAddress; 
    console.log('validateFormData', result, newGame.expectedPlayer2);
    const betAmount = ethers.parseEther(`${bet}`);
    const createName = opponentWasPredefined   
      ? 'createGame'
      : 'createGameSimple';
    const args = opponentWasPredefined 
      ? [betAmount.toString(), newGame.type, gameLength, newGame.expectedPlayer2]
      : [betAmount.toString(), newGame.type, gameLength]
    if (!result.error) {
      const res = await writeContract({
        //@ts-ignore
        value: betAmount.toString(),
        abi,
        address: gomokuContract,
        functionName: createName,
        args,
        gas: BigInt(23000000),  //10000000
        // gasPrice: BigInt(30000000)
      })
      console.log('onNewGame', res, createName)

      // gameBoard.setOpponent(Opponent.HUMAN);
      // gameBoard.setPlayerColor(playerColor);
      // dispatchGameBoard({ type: "reset" });
      onClose();
    } else {
      store.notify.showError(result.error.message);
    }
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(parseInt(event.target.value, 10) as Player.BLACK);
  };

  const handleDifficultyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDifficulty(event.target.value as Difficulty);
  };

  const handleOnNewGame = () => {
    onNewGame(color, difficulty);
  };

  return (
    <Dialog
      className="dialog-modal game-options"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Game Options</DialogTitle>
      <DialogContent className="game-options-body">
        <FormRow className="game-type-formrow">
          <FormControl component="fieldset">
            <FormLabel component="legend">Game size</FormLabel>
            <RadioGroup
              aria-label="play-with"
              name="gameSize"
              value={gameLength}
              onChange={handleGameLengthChange}
            >
              <FormControlLabel
                value={GameSize.DEFAULT}
                control={<CustomRadio />}
                label={<div><span>{GameSize.DEFAULT}</span></div>}
                classes={{ root: gameLength === GameSize.DEFAULT ? "checked img-1" : "" }}
              />
              <FormControlLabel
                value={GameSize.SMALL}
                control={<CustomRadio />}
                label={<div><span>{GameSize.SMALL}</span></div>}
                classes={{ root: gameLength === GameSize.SMALL ? "checked img-2" : "" }}
              />
              <FormControlLabel
                value={GameSize.MEDIUM}
                control={<CustomRadio />}
                label={<div><span>{GameSize.MEDIUM}</span></div>}
                classes={{ root: gameLength === GameSize.MEDIUM ? "checked img-3" : "" }}
              />
              <FormControlLabel
                value={GameSize.BIG}
                control={<CustomRadio />}
                label={<div><span>{GameSize.BIG}</span></div>}
                classes={{ root: gameLength === GameSize.BIG ? "checked img-4" : "" }}
              />
              
              <GridImg 
                  selectedGameSize={
                      gameLength === GameSize.DEFAULT ? "img-1" :
                      gameLength === GameSize.SMALL ? "img-2" :
                      gameLength === GameSize.MEDIUM ? "img-3" : 
                      "img-4"
                  }
              />
            </RadioGroup>
          </FormControl>
        </FormRow>
        <FormRow className="game-type-formrow">
          <FormControl component="fieldset">
            <FormLabel component="legend">Game type</FormLabel>
            <RadioGroup
              aria-label="play-with"
              name="gameType"
              value={newGame.type}
              onChange={handleGameTypeChange}
            >
              <FormControlLabel
                value={GameTypes.BLITZ}
                control={<CustomRadio />}
                label={<div><span>{gameTypeData[GameTypes.BLITZ].name}</span> <span className="radio-description">{gameTypeData[GameTypes.BLITZ].description}</span></div>}
                classes={{ root: newGame.type === GameTypes.BLITZ ? "checked img-5" : "" }}
              />
              <FormControlLabel
                value={GameTypes.BULLET}
                control={<CustomRadio />}
                label={<div><span>{gameTypeData[GameTypes.BULLET].name}</span> <span className="radio-description">{gameTypeData[GameTypes.BULLET].description}</span></div>}
                classes={{ root: newGame.type === GameTypes.BULLET ? "checked img-6" : "" }}
              />
              <FormControlLabel
                value={GameTypes.RAPID}
                control={<CustomRadio />}
                label={<div><span>{gameTypeData[GameTypes.RAPID].name}</span> <span className="radio-description">{gameTypeData[GameTypes.RAPID].description}</span></div>}
                classes={{ root: newGame.type === GameTypes.RAPID ? "checked img-7" : "" }}
              />
              <GridImg 
                  selectedGameSize={
                      newGame.type === GameTypes.BLITZ ? "img-5" :
                      newGame.type === GameTypes.BULLET ? "img-6" :
                      "img-7"
                  }
              />
            </RadioGroup>
          </FormControl>
        </FormRow>
        <FormCol className="columns-row">  
          <FormRow className="game-type-formrow">
            <FormControl component="fieldset">
              <FormLabel component="legend">Play With</FormLabel>
              <RadioGroup
                aria-label="play-with"
                name="playWith"
                value={newGame.opponentType}
                onChange={handleOpponentChange}
              >
                <FormControlLabel
                  value={GameOpponent.ADDRESS}
                  control={<CustomRadio />}
                  label="Address"
                  classes={{ root: newGame.opponentType === GameOpponent.ADDRESS ? "checked" : "" }}
                />
                <FormControlLabel
                  value={GameOpponent.EVERYONE}
                  control={<CustomRadio />}
                  label="Everyone"
                  classes={{ root: newGame.opponentType === GameOpponent.EVERYONE ? "checked" : "" }}
                />
              </RadioGroup>
              {opponentType === GameOpponent.ADDRESS ? (
                <TextField type="text" label="Opponent address" className="opponent-address" onChange={handleOpponentAddress} />
              ) : null}
            </FormControl>

          </FormRow>

          <FormRow className="game-type-formrow">
            <FormControl component="fieldset">
              <FormLabel component="legend">Your Bet</FormLabel>
              <RadioGroup
                aria-label="bet-type"
                name="betType"
                value={newGame.betType}
                onChange={handleGameBetTypeChange}
              >
                <FormControlLabel
                  value={BetType.SET_A_BET}
                  control={<CustomRadio />}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <span>Set a bet</span>
                      <Tooltip title="The 3% fee is applied when placing a bet.">
                        <IconButton size="small" style={{ marginLeft: 8 }} className="info">
                          <FaInfoCircle fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  classes={{ root: newGame.betType === BetType.SET_A_BET ? "checked" : "" }}
                />
                <FormControlLabel
                  value={BetType.NO_BET}
                  control={<CustomRadio />}
                  label="Play for fun"
                  classes={{ root: newGame.betType === BetType.NO_BET ? "checked" : "" }}
                />
                
              </RadioGroup>
              {betType === BetType.SET_A_BET ? (
                <TextField type="number" label="Set value in VLX" className="set-bet" onChange={handleInputValueChange} error={hasError}
                  helperText={hasError ? errorMessage : ''} InputProps={{endAdornment: (
                    <InputAdornment position="start" className="bet-token">
                      <img src={Vlx} alt="vlx" width={20}/>
                    </InputAdornment>
                  )
                }} />

              ) : null}
            </FormControl>

          </FormRow>
        </FormCol>

        {/* Only show color selection if the opponent is AI */}
        {opponent === Opponent.COMPUTER ? (
          <>
            <FormRow>
              <FormControl component="fieldset">
                <FormLabel component="legend">Your Color</FormLabel>
                <RadioGroup
                  aria-label="your-color"
                  name="yourColor"
                  value={color}
                  onChange={handleColorChange}
                >
                  <FormControlLabel
                    value={Player.BLACK}
                    control={<Radio />}
                    label="Black"
                  />
                  <FormControlLabel
                    value={Player.WHITE}
                    control={<Radio />}
                    label="White"
                  />
                </RadioGroup>
              </FormControl>
            </FormRow>

            <FormRow>
              <FormControl component="fieldset">
                <FormLabel component="legend">Level of Difficulty</FormLabel>
                <RadioGroup
                  aria-label="difficulty"
                  name="difficulty"
                  value={difficulty}
                  onChange={handleDifficultyChange}
                >
                  <FormControlLabel
                    value={Difficulty.NOVICE}
                    control={<Radio />}
                    label="Novice"
                  />
                  <FormControlLabel
                    value={Difficulty.MEDIUM}
                    control={<Radio />}
                    label="Medium"
                  />
                  <FormControlLabel
                    value={Difficulty.EXPERT}
                    control={<Radio />}
                    label="Expert"
                  />
                </RadioGroup>
              </FormControl>
            </FormRow>
          </>
        ) : null}
      </DialogContent>
      <DialogActions className="actions">
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <CustomButton disabled={!allRequiredFieldsAreSet || hasError} onClick={handleOnNewGame} color="primary">
          Create Game
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
});

export default NewGameDialog;
