

import * as React from 'react';
import './style.scss';

interface ColumnConfig {
  header: React.ReactNode;
  flex?: number; // Optional flex value for width
}
interface TableProps {
  data: Array<{ [key: string]: any }>;
  columns: ColumnConfig[];
}

const Table: React.FC<TableProps> = ({ data, columns }) => {
  const headers = data.length ? Object.keys(data[0]) : [];
  return (
    <div className="table">
      {/* Table Header */}
      <div className="table-row table-header">
        {columns.map((column, index) => (
          <div 
            key={index} 
            className="table-cell table-header-cell"
            style={{ flex: column.flex || 1 }}  // Set flex basis for header
          >
            {column.header}
          </div>
        ))}
      </div>

      {/* Table Body */}
      {data.map((row, rowIndex) => (
        <div key={rowIndex} className="table-row">
          {headers.map((key, index) => {
            const flex = columns[index]?.flex;
            return (
              <div 
                key={key} 
                className="table-cell table-row-cell"
                style={{ flex: flex || 1 }}
              >
                {row[key]}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default Table;