import React from 'react';
import SvgImages from './SvgImages';

interface GridImgProps {
    selectedGameSize: string;
}

const GridImg: React.FC<GridImgProps> = ({ selectedGameSize }) => {
    return <SvgImages selectedGameSize={selectedGameSize} />;
};

export default GridImg;
