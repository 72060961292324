export default [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "player",
				"type": "address"
			}
		],
		"name": "BetClaimed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "newRate",
				"type": "uint256"
			}
		],
		"name": "CommissionRateUpdated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "CommissionWithdrawn",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "GameCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "player1",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "player2",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "betAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "enum Gomoku.GameTypes",
				"name": "gameType",
				"type": "uint8"
			},
			{
				"indexed": false,
				"internalType": "uint8",
				"name": "gameLength",
				"type": "uint8"
			}
		],
		"name": "GameCreated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "GameDraw",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "GameRewardClaimed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "winner",
				"type": "address"
			}
		],
		"name": "GameWon",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "player",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint8",
				"name": "x",
				"type": "uint8"
			},
			{
				"indexed": false,
				"internalType": "uint8",
				"name": "y",
				"type": "uint8"
			}
		],
		"name": "MoveMade",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "winner",
				"type": "address"
			}
		],
		"name": "RewardClaimed",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "BLITZ_TIME",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "BULLET_TIME",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DEFAULT_SIZE",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "RAPID_TIME",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WIN_CONDITION",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "checkAndEndGameIfTimeout",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "betAmount",
				"type": "uint256"
			},
			{
				"internalType": "enum Gomoku.GameTypes",
				"name": "gameType",
				"type": "uint8"
			},
			{
				"internalType": "uint8",
				"name": "gameLength",
				"type": "uint8"
			},
			{
				"internalType": "address",
				"name": "expectedPlayer2",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "createGame",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "betAmount",
				"type": "uint256"
			},
			{
				"internalType": "enum Gomoku.GameTypes",
				"name": "gameType",
				"type": "uint8"
			},
			{
				"internalType": "uint8",
				"name": "gameLength",
				"type": "uint8"
			},
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "createGameSimple",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gameCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "games",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "expectedPlayer2",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "player1",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "player2",
						"type": "address"
					}
				],
				"internalType": "struct Gomoku.Players",
				"name": "players",
				"type": "tuple"
			},
			{
				"internalType": "uint8",
				"name": "gameLength",
				"type": "uint8"
			},
			{
				"internalType": "enum Gomoku.Player",
				"name": "currentPlayer",
				"type": "uint8"
			},
			{
				"internalType": "enum Gomoku.GameStatus",
				"name": "status",
				"type": "uint8"
			},
			{
				"internalType": "address",
				"name": "winner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "betAmount",
				"type": "uint256"
			},
			{
				"internalType": "enum Gomoku.GameTypes",
				"name": "gameType",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "gameStartedAt",
				"type": "uint256"
			},
			{
				"components": [
					{
						"internalType": "bool",
						"name": "rewardClaimed",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "player1BetClaimed",
						"type": "bool"
					},
					{
						"internalType": "bool",
						"name": "player2BetClaimed",
						"type": "bool"
					}
				],
				"internalType": "struct Gomoku.BetClaims",
				"name": "claims",
				"type": "tuple"
			},
			{
				"components": [
					{
						"internalType": "uint8",
						"name": "row",
						"type": "uint8"
					},
					{
						"internalType": "uint8",
						"name": "column",
						"type": "uint8"
					}
				],
				"internalType": "struct Gomoku.LastPlayedCell",
				"name": "lastPlayedCell",
				"type": "tuple"
			},
			{
				"internalType": "uint256",
				"name": "totalGameTime",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "getGameById",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "id",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "expectedPlayer2",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player1",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player2",
								"type": "address"
							}
						],
						"internalType": "struct Gomoku.Players",
						"name": "players",
						"type": "tuple"
					},
					{
						"internalType": "enum Gomoku.Player[30][30]",
						"name": "board",
						"type": "uint8[30][30]"
					},
					{
						"internalType": "uint8",
						"name": "gameLength",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.Player",
						"name": "currentPlayer",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.GameStatus",
						"name": "status",
						"type": "uint8"
					},
					{
						"internalType": "address",
						"name": "winner",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "betAmount",
						"type": "uint256"
					},
					{
						"internalType": "enum Gomoku.GameTypes",
						"name": "gameType",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "gameStartedAt",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "bool",
								"name": "rewardClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player1BetClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player2BetClaimed",
								"type": "bool"
							}
						],
						"internalType": "struct Gomoku.BetClaims",
						"name": "claims",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "uint8",
								"name": "row",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "column",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.LastPlayedCell",
						"name": "lastPlayedCell",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "playerAddress",
								"type": "address"
							},
							{
								"internalType": "uint256",
								"name": "prevMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "currentMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint8",
								"name": "x",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "y",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.Move[]",
						"name": "movesArray",
						"type": "tuple[]"
					}
				],
				"internalType": "struct Gomoku.GameDetails",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "msgValue",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "joinGame",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "listAllGames",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "id",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "expectedPlayer2",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player1",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player2",
								"type": "address"
							}
						],
						"internalType": "struct Gomoku.Players",
						"name": "players",
						"type": "tuple"
					},
					{
						"internalType": "enum Gomoku.Player[30][30]",
						"name": "board",
						"type": "uint8[30][30]"
					},
					{
						"internalType": "uint8",
						"name": "gameLength",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.Player",
						"name": "currentPlayer",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.GameStatus",
						"name": "status",
						"type": "uint8"
					},
					{
						"internalType": "address",
						"name": "winner",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "betAmount",
						"type": "uint256"
					},
					{
						"internalType": "enum Gomoku.GameTypes",
						"name": "gameType",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "gameStartedAt",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "bool",
								"name": "rewardClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player1BetClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player2BetClaimed",
								"type": "bool"
							}
						],
						"internalType": "struct Gomoku.BetClaims",
						"name": "claims",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "uint8",
								"name": "row",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "column",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.LastPlayedCell",
						"name": "lastPlayedCell",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "playerAddress",
								"type": "address"
							},
							{
								"internalType": "uint256",
								"name": "prevMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "currentMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint8",
								"name": "x",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "y",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.Move[]",
						"name": "movesArray",
						"type": "tuple[]"
					}
				],
				"internalType": "struct Gomoku.GameDetails[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mainContract",
		"outputs": [
			{
				"internalType": "contract IGomokuMain",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "uint8",
				"name": "x",
				"type": "uint8"
			},
			{
				"internalType": "uint8",
				"name": "y",
				"type": "uint8"
			},
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "makeMove",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "playerTimeSpent",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "setGameCanceled",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_mainContractAddress",
				"type": "address"
			}
		],
		"name": "setGameContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "setPlayerBetClaimed",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "setRewardClaimed",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalCommissionCollected",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]