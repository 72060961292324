import { readContract } from '@wagmi/core';
import { config, gomokuContract } from "App/config";
import { ethers } from 'ethers';
import { debounce } from 'helpers';
import { shortenAddress } from "helpers/address";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import React from 'react';
import { gameTypeData } from "stores/NewGame";
import { RootStore } from "stores/RootStore";
import { GameRaw, GameSize, GameStatus, GameTypes, HexString } from "types";
import { Cell, Player } from 'utils/GameBoard/types';
import { abi } from "web3/contract/abi";


type Step = { row: undefined | number, column: undefined | number };
export const emptyAddress = "0x0000000000000000000000000000000000000000";

export class Game {
  @observable winnerCells: Cell[] = [];
  @observable gameRaw: GameRaw | null = null;
  @observable _detailsAreLoading = false;
  @observable temporaryStep: Step = { row: undefined, column: undefined };
  @observable stepTxHash: HexString | null = null;
  @observable step: Step = { row: undefined, column: undefined };
  @observable _turn: 1 | 2 = 1;
  @observable looserCheckedHeLost = false;
  @observable modalDismissed = false;
  @observable isMakingMove = false;
  @observable isCancelingGame = false;
  @observable isClaimingBet = false;
  @observable isClaimingReward = false;


  constructor(
    private appStore: RootStore,
    gameRaw: GameRaw,
  ) {
    makeObservable(this);
    this.gameRaw = gameRaw;

    const dispose = reaction(
      () => this.isFinished,  // the data you're reacting to
      (value) => {
        if (value === true) { // the condition
          this.checkIfWins({
            row: Number(this.lastMove.x.toString()),
            column: Number(this.lastMove.y.toString()),
          })
    
          dispose(); // Dispose after first execution
        }
      }
    );
  }

  @computed
  get gameFinishedAt() {
    if (!this.isFinished) return undefined;
    return this.lastMove.currentMoveTs;
  }

  @computed
  get showGameLoader() {
    return this.isMakingMove || this.isCancelingGame || this.isClaimingBet || this.isClaimingReward;
  }

  @computed
  get id() {
    return this.gameDetails?.id?.toString()!;
  }

  @computed
  get isDraft() {
    return this.isFinished && this.emptyCellsCount === 0 && this.winner === emptyAddress;
  }

  @computed
  get gameDetails() {
    return this.gameRaw!;
  }

  @computed
  get gameLength(): GameSize {
    return this.gameDetails.gameLength;
  }

  @computed
  get lastPlayedCell(): Cell | undefined {
    return this.gameDetails.lastPlayedCell;
  }

  @computed
  get board(): Array<Array<number>> {
    return this.gameDetails?.board || [];
  }

  @computed
  get status() {
    return this.gameDetails?.status || 0;
  }

  @computed
  get author() {
    return this.player1;
  }

  @computed
  get player1() {
    return this.gameDetails?.players?.player1 || emptyAddress;
  }

  @computed
  get player1Short() {
    return this.appStore.profileStore.nicknamesMap[this.player1] || shortenAddress(this.player1);
  }

  @computed
  get player2() {
    return this.gameDetails?.players?.player2 || emptyAddress;
  }

  @computed
  get player2Short() {
    return this.appStore.profileStore.nicknamesMap[this.player2] || shortenAddress(this.player2);
  }

  @computed
  get expectedPlayer2() {
    return this.gameDetails?.players.expectedPlayer2 || emptyAddress;
  }

  @computed
  get isPrivate() {
    return this.expectedPlayer2 !== emptyAddress;
  }

  @computed
  get expectedPlayer2WasSet() {
    return !!this.gameDetails?.players.expectedPlayer2 && this.gameDetails?.players.expectedPlayer2 !== emptyAddress;
  }

  @computed
  get canAccessPrivateGame() {
    if (this.author === this.appStore.myAddress) {
      return true;
    }
    return this.expectedPlayer2WasSet && this.appStore.myAddress === this.expectedPlayer2;
  }

  @computed
  get canJoinGame() {
    if (this.isMyGame) return false;
    if (this.player2 && this.player2 !== emptyAddress) {
      return false;
    }
    if (!this.isPrivate && this.isPlayer2Absent) {
      return true;
    }
    if (this.isPrivate && this.canAccessPrivateGame) {
      return true;
    }
    return false;
  }

  @computed
  get canShowJoinGameButton() {
    if (this.isMyGame) return false;
    if (this.expectedPlayer2WasSet && this.expectedPlayer2 !== this.appStore.myAddress) {
      return true;
    }
    return false;
  }

  @computed
  get winner() {
    return this.player2RunOutOfTime
      ? this.player1
      : this.player1RunOutOfTime
        ? this.player2
        : this.gameDetails?.winner || emptyAddress;
  }

  @computed
  get displayedWinner() {
    return this.winner !== emptyAddress 
      ? this.winner
      : this.isTimeoutWinnerGame
        ? this.player1RunOutOfTime 
          ? this.player2
          : this.player1
        : emptyAddress;
  }

  @computed
  get displayedWinnerShort() {
    return this.appStore.profileStore.nicknamesMap[this.displayedWinner] || shortenAddress(this.displayedWinner);
  }


  @computed
  get hasDisplayedWinner() {
    return this.displayedWinner !== emptyAddress;
  }

  @computed
  get winnerIndex(): 0 | 1 | 2 {
    return this.winner === this.player1
      ? 1
      : this.winner === this.player2
        ? 2
        : 0;
  }

  @computed
  get turn() {
    return this.currentPlayer;
  }

  @computed
  get winnerIsMe() {
    return this.winner === this.appStore.myAddress;
  }

  @computed
  get looserIsMe() {
    return this.winner !== emptyAddress && this.winner !== this.appStore.myAddress;
  }

  @computed
  get winnerShort() {
    return this.appStore.profileStore.nicknamesMap[this.winner] || shortenAddress(this.winner);
  }

  @computed
  get currentPlayer() {
    return this.gameDetails?.currentPlayer;
  }

  @computed
  get betAmountRaw() {
    return this.gameDetails?.betAmount || BigInt.call(this, 0);
  }

  @computed
  get betAmountGwei() {
    return this.betAmountRaw.toString();
  }

  @computed
  get betAmount() {
    return +this.betAmountGwei / 1e18;
  }

  @computed
  get betAmountSting() {
    return this.betAmount.toString();
  }

  @computed
  get gameType() {
    return this.gameDetails?.gameType || GameTypes.BLITZ;
  }

  @computed
  private get gameTypeData() {
    return gameTypeData[this.gameType];
  }

  @computed
  get timeLimitMinutes() {
    return this.gameTypeData.minutes;
  }

  @computed
  get timeLimitSeconds() {
    return this.gameTypeData.minutes * 60;
  }

  @computed
  get timeLimitMs() {
    return this.gameTypeData.minutes * 60 * 1000;
  }

  @computed
  get hasWinner() {
    return this.status === GameStatus.FINISHED && this.winner !== emptyAddress;
  }

  @computed
  get takingPart() {
    return this.player1 === this.appStore.myAddress || this.player2 === this.appStore.myAddress;
  }

  @computed
  get isPlayer2Absent() {
    return !this.player2 || this.player2 === emptyAddress;
  }

  @computed
  get isMyGame() {
    return this.player1 === this.appStore.myAddress;
  }

  @computed
  get isFinished() {
    return this.status === GameStatus.FINISHED;
  }

  @computed
  get winningsWasClaimed() {
    return this.gameDetails.claims.rewardClaimed;
  }

  @computed
  get gameStartedAt() {
    return this.gameDetails.gameStartedAt;
  }

  @computed
  get alreadyStarted() {
    return this.gameStartedAt !== BigInt(0);
  }

  @computed
  get currentTurn() {
    return this.currentPlayer;
  }

  @computed
  get comeInButtonDisabled() {
    return this.status === GameStatus.FINISHED;
  }

  @computed
  get detailsAreLoading() {
    return this._detailsAreLoading;
  }

  @computed
  get hasBet() {
    return this.betAmount > 0;
  }

  @computed 
  get claimBetAvailable() {
    return this.emptyCellsCount === 0 && this.betAmount !== 0 && !this.iAlreadyClaimedMyBet;
  }

  @computed
  get myPlayerIndex() {
    return this.appStore.myAddress === this.player1 ? 1 : 2;
  }

  @computed
  get opponentIndex() {
    return this.myPlayerIndex === 1 ? 2 : 1;
  }

  @computed
  get iAlreadyClaimedMyBet() {
    if (this.myPlayerIndex === 1 && this.gameDetails?.claims?.player1BetClaimed) {
      return true;
    }
    if (this.myPlayerIndex === 2 && this.gameDetails?.claims?.player2BetClaimed) {
      return true;
    }
    return false;
  }

  @computed
  get madeStepsCount(): number {
    return this.board.reduce((acc, current) => {
      const summ = current.reduce((acc1, current1) => {
        return current1 !== 0 ? acc1 + 1 : acc1;
      }, 0);
      return acc + summ;
    }, 0)
  }

  @computed
  get emptyCellsCount() {
    return Math.pow(this.gameLength, 2) - this.madeStepsCount;
  }

  @computed
  get iHaveLostGame() {
    return this.winner !== emptyAddress && this.appStore.myAddress !== this.winner;
  }

  @computed
  get moves() {
    return this.gameDetails.movesArray;
  }

  @computed
  get lastMove() {
    return this.moves[this.moves.length - 1];
  }

  @computed
  get lastMovePlayer1() {
    return this.movesOfPlayer1[this.movesOfPlayer1.length - 1];
  }

  @computed
  get lastMovePlayer2() {
    return this.movesOfPlayer2[this.movesOfPlayer2.length - 1];
  }


  @computed
  get movesOfPlayer1() {
    return this.moves.filter(move => move.playerAddress === this.player1);
  }
  
  @computed
  get timeElapsedPlayer1BigInt() {
    return this.movesOfPlayer1.reduce((acc, curr) => acc + (curr.currentMoveTs - curr.prevMoveTs), BigInt(0))
  }

  @computed
  get timeElapsedPlayer1() {
    return Number(this.movesOfPlayer1.reduce((acc, curr) => acc + (curr.currentMoveTs - curr.prevMoveTs), BigInt(0)).toString());
  }

  @computed
  get currentStepElapsedTimePlayer1BigInt() {
    if (this.currentTurn === 2) return 0;
    const lastStep = this.movesOfPlayer2[this.movesOfPlayer2.length - 1];
    if (!lastStep) return 0;
    const lastStepTs = lastStep.currentMoveTs;
    if (this.isFinished && !!this.gameFinishedAt) {
      return (this.gameFinishedAt - lastStepTs) < 0 ? BigInt(0) : this.gameFinishedAt - lastStepTs;
    }
    const blockTimestamp = this.appStore.blockTimestampStore.blockTimestamp || BigInt(0);
    return (blockTimestamp - lastStepTs) < 0 ? BigInt(0) : blockTimestamp - lastStepTs;
  }

  @computed
  get currentStepElapsedTimePlayer1() {
    return Number(this.currentStepElapsedTimePlayer1BigInt.toString());
  }

  @computed
  get timeLeftPlayer1() {
    if (this.status === GameStatus.FINISHED && this.movesOfPlayer1.length === 0) {
      return 0;
    }
    const res = (this.timeLimitSeconds - (this.timeElapsedPlayer1 + this.currentStepElapsedTimePlayer1)) < 0
    ? 0
    : this.timeLimitSeconds - (this.timeElapsedPlayer1 + this.currentStepElapsedTimePlayer1);
    return res;
  }



  @computed
  get movesOfPlayer2() {
    return this.moves.filter(move => move.playerAddress === this.player2);
  }

  @computed
  get timeElapsedPlayer2BigInt() {
    return this.movesOfPlayer2.reduce((acc, curr) => acc + (curr.currentMoveTs - curr.prevMoveTs), BigInt(0))
  }

  @computed
  get timeElapsedPlayer2() {
    return Number(this.movesOfPlayer2.reduce((acc, curr) => acc + (curr.currentMoveTs - curr.prevMoveTs), BigInt(0)).toString());
  }

  @computed
  get currentStepElapsedTimePlayer2BigInt() {
    if (this.currentTurn === 1) return 0;
    const lastStep = this.movesOfPlayer1[this.movesOfPlayer1.length - 1];
    if (!lastStep) return 0;
    const lastStepTs = lastStep.currentMoveTs;
    if (this.isFinished && this.gameFinishedAt) {
      return (this.gameFinishedAt - lastStepTs) < 0 ? BigInt(0) : this.gameFinishedAt - lastStepTs;
    }
    const blockTimestamp = this.appStore.blockTimestampStore.blockTimestamp || BigInt(0);
    return (blockTimestamp - lastStepTs) < 0 ? BigInt(0) : blockTimestamp - lastStepTs;
  }

  @computed
  get currentStepElapsedTimePlayer2() {
    return Number(this.currentStepElapsedTimePlayer2BigInt.toString());
  }

  // @computed
  // get isTimeSynchronized() {
    
  // }

  @computed
  get gameIsNotActive() {
    return this.timeLeftPlayer1 !== undefined && this.timeLeftPlayer1 <= 0 || this.timeLeftPlayer2 !== undefined && this.timeLeftPlayer2 <= 0;
  }

  @computed
  get player1RunOutOfTime() {
    return this.timeLeftPlayer1 !== undefined && this.timeLeftPlayer1 <= 0;
  }

  @computed
  get player2RunOutOfTime() {
    return this.timeLeftPlayer2 !== undefined && this.timeLeftPlayer2 <= 0;
  }

  @computed
  get meRunOutOfTime() {
    return (this.myPlayerIndex === 1 && this.player1RunOutOfTime) || (this.myPlayerIndex === 2 && this.player2RunOutOfTime);
  }

  @computed
  get opponentRunOutOfTime() {
    return (this.opponentIndex === 1 && this.player1RunOutOfTime) || (this.opponentIndex === 2 && this.player2RunOutOfTime);
  }

  @computed
  get playersHaveNotRunOutOfTimes() {
    return !this.player1RunOutOfTime && !this.player2RunOutOfTime;
  }

  @computed
  get showWinButton() {
    return this.winnerIsMe && !this.winningsWasClaimed && this.hasBet && !this.isTimeoutWinnerGame;
  }

  @computed
  get isTimeoutWinnerGame() {
    console.log('isTimeoutWinnerGame', this.player1RunOutOfTime, this.player2RunOutOfTime)
    return this.player1RunOutOfTime || this.player2RunOutOfTime;
  }

  @computed
  get showAbortGameButton() {
    return this.moves.length === 0 && !this.wasCanceled && this.appStore.isConnected && this.takingPart;
  }

  @computed
  get showWinAndFinishedGameButton() {
    return (this.gameIsNotActive && !this.meRunOutOfTime && !this.isFinished && this.winnerIsMe) || (this.winner === emptyAddress && this.opponentRunOutOfTime);
  }

  @computed
  get timeLeftPlayer2() {
    if (this.status === GameStatus.FINISHED && this.movesOfPlayer2.length === 0) {
      return 0;
    }
    return (this.timeLimitSeconds - (this.timeElapsedPlayer2 + this.currentStepElapsedTimePlayer2)) < 0 
      ? 0
      : this.timeLimitSeconds - (this.timeElapsedPlayer2 + this.currentStepElapsedTimePlayer2);
  }

  @computed
  get wasCanceled() {
    return this.status === GameStatus.CANCELED;
  }

  @computed
  get hideCancelGameButton() {
    return this.moves.length > 0 || this.wasCanceled;
  }


  @action.bound
  getWinnerByIndex(index: Player) {
    return index === 1 ? this.player1 : this.player2;
  }

  @action.bound
  navigateToGame() {
    this._detailsAreLoading = true;
    this.appStore.gameStore.currentGameId = this.id;
    this.appStore.navigate(`/game/${this.id}`);
  }

  private createMessageElement(hash: HexString, message: string) {
    return React.createElement(
      'div',
      null,
      message,
      React.createElement(
        'a',
        { href: `https://evmexplorer.velas.com/tx/${hash}`, target: '_blank', rel: 'noopener noreferrer' },
        'Transaction receipt'
      )
    );
  };

  @action.bound
  async joinGame() {
    if (this.appStore.writeContract) {
      const { writeContractAsync } = this.appStore.writeContract;
      try {
        const betAmountValue = ethers.parseEther(`${this.betAmount}`);
        const hash = await writeContractAsync({
          value: betAmountValue,
          abi,
          address: gomokuContract,
          functionName: 'joinGame',
          args: [this.id],
        });
        if (hash) {
          this.appStore.notify.showSuccess('You have successfully joined the game!');
          setTimeout(() => {
            this.navigateToGame();
          }, 2000);
        }
      } catch (error) {
        this.appStore.notify.handleError(error);
      }
    }
  }

  @action.bound
  async claimOwnBet() {
    if (this.appStore.writeContract) {
      this.isClaimingBet = true;
      const { writeContractAsync } = this.appStore.writeContract;
      try {
        const hash = await writeContractAsync({
          abi,
          address: gomokuContract,
          functionName: 'claimBet',
          args: [this.id, this.appStore.myAddress],
        });
        if (hash) {
          this.appStore.notify.showSuccess(this.createMessageElement(hash, 'You have claimed your bet amount successfully. '))
          this.clearGameFinishedModal();
        }
      } catch (error) {
        this.appStore.notify.handleError(error);
      } finally {
        this.isClaimingBet = false;
      }
    }
  }

  @action.bound
  async claimBets() {
    if (this.appStore.writeContract) {
      this.isClaimingReward = true;
      const { writeContractAsync } = this.appStore.writeContract;
      try {
        const hash = await writeContractAsync({
          abi,
          address: gomokuContract,
          functionName: 'claimReward',
          args: [this.id, this.appStore.profileStore.myAddress],
        });
        if (hash) {
          this.appStore.notify.showSuccess(this.createMessageElement(hash, 'You have claimed your winnings successfully. '))
          this.clearGameFinishedModal();
        }
      } catch (error) {
        this.appStore.notify.handleError(error);
      } finally {
        this.isClaimingReward = false;
      }
    }
  }

  @action.bound
  async cancelGame() {
    if (this.appStore.writeContract) {
      this.isCancelingGame = true;
      const { writeContractAsync } = this.appStore.writeContract;
      try {
        const hash = await writeContractAsync({
          abi,
          address: gomokuContract,
          functionName: 'cancelGame',
          args: [this.id],
        });
        if (hash) {
          this.appStore.notify.showSuccess('Game was canceled');
          this.clearGameFinishedModal();
          this.appStore.navigate('/');
        }
      } catch (error) {
        this.appStore.notify.handleError(error);
      }
      finally {
        this.isCancelingGame = false;
      }
    }
  }
  @action.bound
  openAbortConfirm() {
    this.appStore.gameStore.gameFinishedModal.type = 'quit';
    this.appStore.gameStore.gameFinishedModal.content = 
      "Quit the game? It will be deleted with no way to return.";
    this.appStore.gameStore.gameFinishedModal.onClaim = this.confirmAbortGame;
    this.appStore.gameStore.gameFinishedModal.buttonName = "Confirm";
    this.appStore.gameStore.gameFinishedModal.isOpen = true;
  }

  @action.bound
  closeAbortConfirm() {
    this.clearGameFinishedModal();
  }

  @action.bound
  async confirmAbortGame() {
    this.closeAbortConfirm();
    await this.cancelGame();
  }

  @action.bound
  async fetchGameDetails() {
    const result = await readContract(config, {
      abi,
      address: gomokuContract,
      functionName: 'getGameById',
      args: [this.id],
    });
    this.gameRaw = result as GameRaw;
  }

  @action.bound
  async checkAndEndGameWithWin() {
    if (this.appStore.writeContract) {
      this.isClaimingReward = true;
      const { writeContractAsync } = this.appStore.writeContract;
      try {
        const hash = await writeContractAsync({
          abi,
          address: gomokuContract,
          functionName: 'checkAndEndGameIfTimeout', 
          args: [this.id],
          gas: BigInt(4000000),
        });
        if (hash) {
          this.appStore.notify.showSuccess(this.createMessageElement(hash, 'You have claimed your winnings successfully. '))
          this.clearGameFinishedModal();
        }
      } catch (error) {
        this.appStore.notify.handleError(error);
      } finally {
        this.isClaimingReward = false;
      }
    }
    // if (this.iHaveLostGame && !this.looserCheckedHeLost) {
    //   const dialogContent = "Oops, you lose";
    //   this.appStore.gameStore.gameFinishedModal.content = dialogContent;
    //   this.appStore.gameStore.gameFinishedModal.isOpen = true;
    //   this.looserCheckedHeLost = true;
    // }
  }

  @action.bound
  clickCell = debounce(this.cellClickHandler, 500);

  @action.bound
  async cellClickHandler(event: React.SyntheticEvent<HTMLButtonElement>) {
    if (this.appStore.writeContract) {
      if (!(event.target instanceof HTMLButtonElement)) {
        return;
      }
      if (!event.target.dataset.row || !event.target.dataset.column) {
        return;
      }
      if ((this.temporaryStep.column || this.temporaryStep.row)) {
        return;
      }
      this.isMakingMove = true;

      const { writeContractAsync } = this.appStore.writeContract;

      try {
        const row = parseInt(event.target.dataset.row, 10);
        const column = parseInt(event.target.dataset.column, 10);
        this.temporaryStep.row = row;
        this.temporaryStep.column = column;
        const hash = await writeContractAsync({
          abi,
          address: gomokuContract,
          functionName: 'makeMove',
          args: [this.id, row, column],

        });
        this.stepTxHash = hash;
        this.step.column = column;
        this.step.row = row;
        this.checkIfWins({
          row,
          column
        });
        // this.lastPlayedCell = { row, column };

        // dispatchGameBoard({ type: 'requestState' });
      } catch (error: any) {
        this.appStore.notify.handleError(error);
        console.error(error.message || error);
      } finally {
        this.temporaryStep.row = undefined;
        this.temporaryStep.column = undefined;
        this.isMakingMove = false;
      }
    }
  }

  @action.bound
  clearGameFinishedModal() {
    this.appStore.gameStore.gameFinishedModal.onClaim = undefined;
    this.appStore.gameStore.gameFinishedModal.content = '';
    this.appStore.gameStore.gameFinishedModal.buttonName = '';
    this.appStore.gameStore.gameFinishedModal.isOpen = false;
    this.modalDismissed = true;
  }

  private checkIfWins(cell: Cell) {
    // first of all push the current cell as we know it's a winner:
    this.winnerCells = [cell];
    /* ----------- CHECK CURRENT ROW ------------- */
    // Check current row forward direction ->
    for (let i = cell.column + 1; i < this.gameLength; i++) {
      if (this.board[cell.row][i] === this.turn) {
        this.winnerCells.push({ row: cell.row, column: i });
      } else {
        break;
      }
    }

    if (this.winnerCells.length >= 5) {
      return;
    }

    // Check current row backward direction <-
    for (let i = cell.column - 1; i >= 0; i--) {
      if (this.board[cell.row][i] === this.turn) {
        this.winnerCells.push({ row: cell.row, column: i });
      } else break;
    }

    if (this.winnerCells.length >= 5) {
      return;
    }

    /* ----------- CHECK CURRENT COLUMN ------------- */
    // Check current column forward direction ->
    this.winnerCells = [cell];

    for (let i = cell.row + 1; i < this.gameLength; i++) {
      if (this.board[i][cell.column] === this.turn) {
        this.winnerCells.push({ row: i, column: cell.column });
      } else break;
    }

    if (this.winnerCells.length >= 5) {
      return;
    }

    // Check current column backward direction <-
    for (let i = cell.row - 1; i >= 0; i--) {
      if (this.board[i][cell.column] === this.turn) {
        this.winnerCells.push({ row: i, column: cell.column });
      } else break;
    }

    if (this.winnerCells.length >= 5) {
      return;
    }

    /* ----------- CHECK MAJOR DIAGONAL [  ╲  ] ------------- */
    this.winnerCells = [cell];

    // Helper function
    const max = (x: number, y: number) => {
      return x > y ? x : y;
    };

    for (let i = 1; i < max(this.gameLength, this.gameLength); i++) {
      if (cell.row + i >= this.gameLength || cell.column + i >= this.gameLength) {
        break;
      }

      if (this.board[cell.row + i][cell.column + i] === this.turn) {
        this.winnerCells.push({ row: cell.row + i, column: cell.column + i });
      } else break;
    }

    if (this.winnerCells.length >= 5) {
      return;
    }

    for (let i = 1; i < max(this.gameLength, this.gameLength); i++) {
      if (cell.row - i < 0 || cell.column - i < 0) {
        break;
      }

      if (this.board[cell.row - i][cell.column - i] === this.turn) {
        this.winnerCells.push({ row: cell.row - i, column: cell.column - i });
      } else break;
    }

    if (this.winnerCells.length >= 5) {
      return;
    }

    //
    //
    /* ----------- CHECK MINOR DIAGONAL [  ╱  ] ------------- */
    this.winnerCells = [cell];

    for (let i = 1; i < max(this.gameLength, this.gameLength); i++) {
      if (cell.row - i < 0 || cell.column + i > this.gameLength) {
        break;
      }

      if (this.board[cell.row - i][cell.column + i] === this.turn) {
        this.winnerCells.push({ row: cell.row - i, column: cell.column + i });
      } else break;
    }

    if (this.winnerCells.length >= 5) {
      return;
    }

    for (let i = 1; i < max(this.gameLength, this.gameLength); i++) {
      if (cell.row + i >= this.gameLength || cell.column - i < 0) {
        break;
      }

      if (this.board[cell.row + i][cell.column - i] === this.turn) {
        this.winnerCells.push({ row: cell.row + i, column: cell.column - i });
      } else break;
    }

    if (this.winnerCells.length >= 5) {
      return;
    }
  }

}