import React from 'react';
import Avatar, { genConfig } from 'react-nice-avatar';

type AvatarProps = {
  address?: string; // Hex address in string format,
  width?: number;
  height?: number;
  showDefaultAvatar?: boolean;
};

const generateSeedFromAddress = (address: string): number => {
  let seed = 0;

  for (let i = 0; i < address.length; i++) {
    const charCode = address.charCodeAt(i);
    seed = (seed * 31 + charCode) % 0x100000000; // Using prime number 31 for better distribution
  }

  return seed;
};

const getAvatarConfig = (address: string) => {
  const seed = generateSeedFromAddress(address);
  // Use seed for consistent randomness
  return genConfig({
    sex: seed % 2 === 0 ? 'man' : 'woman',
    faceColor: seed % 5 === 0 ? '#F9C9B6' : '#AC6651',
    earSize: seed % 3 === 0 ? 'small' : 'big',
    hairStyle: seed % 4 === 0 ? 'normal' : seed % 3 === 0 ? 'thick' : 'mohawk',
    hairColor: seed % 2 === 0 ? '#000' : '#A55728',
    hatStyle: seed % 4 === 0 ? 'none' : 'none',
    hatColor: seed % 4 === 0 ? 'green' : 'blue',
    eyeStyle: seed % 2 === 0 ? 'circle' : 'oval',
    glassesStyle: seed % 5 === 0 ? 'round' : 'none',
    noseStyle: seed % 3 === 0 ? 'short' : 'long',
    mouthStyle: seed % 2 === 0 ? 'smile' : 'laugh',
    shirtStyle: seed % 2 === 0 ? 'hoody' : 'short',
    shirtColor: seed % 3 === 0 ? '#F4D150' : '#77311D',
    bgColor: seed % 2 === 0 ? '#E0DDFF' : '#F4D150',
    isGradient: false,
  });
};

const DEFAULT_AVATAR = ({ width, height }: Pick<AvatarProps, 'width' | 'height'>) => (
  
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M28 54.6406C42.7132 54.6406 54.6406 42.7132 54.6406 28C54.6406 13.2868 42.7132 1.35938 28 1.35938C13.2868 1.35938 1.35938 13.2868 1.35938 28C1.35938 42.7132 13.2868 54.6406 28 54.6406Z" fill="#97A1AE"/>
    <path d="M49.3787 43.8768C46.8941 40.5504 43.6678 37.8493 39.9564 35.9883C36.2449 34.1272 32.1505 33.1576 27.9986 33.1563C23.8467 33.1549 19.7517 34.1221 16.0391 35.9808C12.3264 37.8395 9.09851 40.5385 6.61182 43.8634C9.08587 47.2051 12.3085 49.9206 16.0214 51.7922C19.7342 53.6638 23.834 54.6394 27.9919 54.6407C32.1498 54.642 36.2501 53.669 39.9642 51.7997C43.6782 49.9305 46.9025 47.217 49.3787 43.8768Z" fill="white"/>
    <path d="M28 29.7188C33.6954 29.7188 38.3125 25.1017 38.3125 19.4062C38.3125 13.7108 33.6954 9.09375 28 9.09375C22.3046 9.09375 17.6875 13.7108 17.6875 19.4062C17.6875 25.1017 22.3046 29.7188 28 29.7188Z" fill="white"/>
  </svg>

);

const HexAvatar: React.FC<AvatarProps> = ({
  address,
  width = 100,
  height = 100,
}) => {
  if (!address) {
    return <DEFAULT_AVATAR width={width} height={height} />;
  }
  const config = getAvatarConfig(address);
  return <Avatar style={{width, height}} {...config} />;
};

export default HexAvatar;