import './styles.scss';

export const LoaderLight = ({ show, text }: { show: boolean, text?: string }) => {
  if (!show) return null;
  return (
    <div className='loader-overlay'>
      <div className='loader' role="status">
        <label>{!text ? "waiting for request confirm..." : text}</label>
        <div className="loading"></div>
      </div>
    </div>
  );
}