import { action, makeObservable, observable } from "mobx";
import { Game } from "models/Game";

export class SearchModel {
  @observable searchQuery: string = '';

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setSearchQuery(query: string) {
    this.searchQuery = query;
  }

  filterGames(games: Game[]): Game[] {
    if (!this.searchQuery) return games;
    const lowerSearchQuery = this.searchQuery.toLowerCase();
    return games.filter(game => {
      const idMatch = game.id.toLowerCase().includes(lowerSearchQuery);
      const player1Match = game.player1Short && game.player1Short.toLowerCase().includes(lowerSearchQuery);
      const player2Match = game.player2Short && game.player2Short.toLowerCase().includes(lowerSearchQuery);
      const betAmountMatch = game.betAmount !== undefined && game.betAmount.toString().includes(lowerSearchQuery);
      const timeLimitMatch = game.timeLimitMinutes !== undefined && game.timeLimitMinutes.toString().includes(lowerSearchQuery);
      const gameLengthMatch = game.gameLength !== undefined && game.gameLength.toString().includes(lowerSearchQuery);
      const winnerMatch = game.winnerShort && game.winnerShort.toLowerCase().includes(lowerSearchQuery);
  
      return (
        idMatch ||
        player1Match ||
        player2Match ||
        betAmountMatch ||
        timeLimitMatch ||
        gameLengthMatch ||
        winnerMatch
      );
    });
  }

  filterPlayers(players: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> {
    if (!this.searchQuery) return players;
    const lowerSearchQuery = this.searchQuery.toLowerCase();
    return players.filter(player => {
      const playerNameMatch = player?.Player?.props?.children?.[1]?.toLowerCase().includes(lowerSearchQuery);
      const timeSpentMatch = player?.timeSpent?.props?.children?.toString()?.toLowerCase().includes(lowerSearchQuery);
      const gamesCountMatch = player?.gamesCount?.props?.children?.toString()?.includes(lowerSearchQuery);
      const winPercentageMatch = player?.winPercentage?.props?.children?.toString()?.includes(lowerSearchQuery);
      const scoreMatch = player?.Score?.toString()?.includes(lowerSearchQuery);

      return (
        playerNameMatch ||
        timeSpentMatch ||
        gamesCountMatch ||
        winPercentageMatch ||
        scoreMatch
      );
    });
  }

  filterLeaderboard(players: Array<{ [key: string]: any }>): Array<{ [key: string]: any }> {
    if (!this.searchQuery) return players;
    const lowerSearchQuery = (this.searchQuery || '').toLowerCase();
    return players.filter(player => {
      const playerAddressMatch = player?.Player?.props?.children?.props?.address?.toLowerCase().includes(lowerSearchQuery);
      const playerNicknameMatch = player?.Player?.props?.children?.props?.value?.toLowerCase().includes(lowerSearchQuery);
      return playerAddressMatch || playerNicknameMatch;
    });
  }
}
