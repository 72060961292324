export function isBigInt(value: any) {
  return Object.prototype.toString.call(value).substring(8, 14) === 'BigInt';
}

export function debounce(func: Function, delay: number) {
  let timeout = -1;
  return function(...args: any) {
    //@ts-ignore
    const context = this;
    clearTimeout(timeout); // Clear the previous timer
    timeout = setTimeout(() => func.apply(context, args), delay); // Set a new timer
  };
}