import { isValidAddress } from "helpers/address";
import { action, computed, makeObservable, observable } from "mobx";
import { BetType, Colors, GameSize, GameTypes, HexAddress, Opponent } from "types";
import { RootStore } from "./RootStore";

export const gameTypeData = {
  [GameTypes.BLITZ]: {
    name: 'Blitz',
    minutes: 5,
    description: 'Fast games with 5 minutes per player.',
  },
  [GameTypes.BULLET]: {
    name: 'Bullet',
    minutes: 3,
    description: 'Faster than Blitz, 3 minutes per player.',
  },
  [GameTypes.RAPID]: {
    name: 'Rapid',
    minutes: 30,
    description: 'Slower than Blitz, 30 minutes per player.',
  },
}


export class NewGame {
  @observable type: GameTypes = GameTypes.BLITZ;
  @observable bet: number = 0;
  @observable betType: BetType = BetType.NO_BET;
  @observable color: Colors = Colors.BLACK;
  @observable opponentType: Opponent = 0;
  @observable expectedPlayer2: HexAddress | null = null;
  @observable _gameLength: GameSize = GameSize.DEFAULT;
  @observable hasError: boolean = false;
  @observable errorMessage: string = '';

  constructor(private appState: RootStore) {
    makeObservable(this);
  }

  @computed
  get gameType() {
    return gameTypeData[this.type];
  }

  @computed
  get gameDurationMinutes() {
    return this.gameType.minutes;
  }

  @computed
  get gameTypeDescription() {
    return this.gameType.description;
  }

  @computed
  get createFormData() {
    return {
      type: this.type,
      bet: this.bet,
      color: this.color,
      opponent: this.opponent,
    }
  }

  @computed
  get gameLength() {
    return this._gameLength;
  }

  @computed
  get opponent() {
    if (this.opponentType === Opponent.EVERYONE) {
      return null;
    }
    return this.expectedPlayer2;
  }

  @computed
  get allRequiredFieldsAreSet() {
    console.log('this.gameType', this.opponentType, this.expectedPlayer2, this.gameDurationMinutes)

    if (this.opponentType === Opponent.ADDRESS && !this.expectedPlayer2) {
      return false;
    }
    if (!this.gameDurationMinutes) {
      return false;
    }

    if (this.betType === BetType.SET_A_BET && (this.bet <= 0 || isNaN(this.bet))) {
      return false;
    }
    
    return true;
  }

  @action.bound
  handleGameLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this._gameLength = +event.target.value;
  };

  @action.bound
  handleOpponentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.opponentType = +event.target.value;
  };

  @action.bound
  handleGameTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.type = (+event.target.value as GameTypes);
  };

  @action.bound
  handleOpponentAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.expectedPlayer2 = event.target.value;
  };

  @action.bound
  handleGameBetChange = (event: React.ChangeEvent<HTMLInputElement>, availableBalance: string) => {
    const value = +event.target.value;
    console.log('handleGameBetChange', value, value < 0)
    if (value === 0 || isNaN(value)) {
      this.hasError = true;
      this.errorMessage = 'Bet amount must be greater than 0';
      return this.bet = 0;
    }

    if (value > +availableBalance) {
      this.hasError = true;
      this.errorMessage = `Insufficient funds. Your balance: ${availableBalance} VLX`;
    } else {
      this.hasError = false;
      this.errorMessage = '';
    }

    if (!isNaN(value) && value > 0) {
      this.bet = (value as number);
    }
  };

  @action.bound
  handleGameBetTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.betType = (+event.target.value as BetType);

    if (this.betType === BetType.NO_BET) {
      this.bet = 0;
      this.hasError = false;
      this.errorMessage = '';
    }
  };

  @action.bound
  validateFormData(): { error: { message: string;} | null } {
    if (this.type === undefined) {
      return {
        error: {
          message: 'no type was defined',
        }
      }
    }
    if (isNaN(this.bet)) {
      return {
        error: {
          message: 'no bet was defined',
        }
      }
    }
    if (isNaN(this.color)) {
      return {
        error: {
          message: 'no color was defined',
        }
      }
    }
    console.log('isValidAddress(this.expectedPlayer2)',isValidAddress(this.expectedPlayer2 as string) )
    if (this.opponentType === Opponent.ADDRESS && this.expectedPlayer2 && !isValidAddress(this.expectedPlayer2)) {
      return {
        error: {
          message: 'Address is not valid',
        }
      }
    }
    // if (this.expectedPlayer2 === this.appState.myAddress) {
    //   return {
    //     error: {
    //       message: 'Cannot set my own address as opponent',
    //     }
    //   }
    // }
    return { error: null };
  }

}