import { ConnectButton } from '@rainbow-me/rainbowkit';
import { velas } from 'App/config';
import HexAvatar from 'components/Avatar';
import { useStore } from 'hooks/useStore';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAccount, useBalance, usePublicClient } from 'wagmi';
import LogoDark from '../../assets/imgs/logo-dark.svg';
import Logo from '../../assets/imgs/logo.svg';
import '../../pages/Games/style.scss';
import './style.scss';

export const Header = () => {
  const store = useStore();
  const { address, isConnected, chain } = useAccount();
  const publicClient = usePublicClient();
  const { profileStore } = store;

  const isWrongNetwork = chain?.id !== velas.id;

  const { refetch } = useBalance({
    address,
  });
  useEffect(() => { profileStore.myAddress = address; }, [address]);

  // Refresh ConnectButton balance
  useEffect(() => {
    if (publicClient && address) {
      const handleBlock = async () => {
        await refetch(); // Refresh balance on every new block
      };

      const unsubscribe = publicClient.watchBlocks({
        onBlock: handleBlock, // Block listener
      });

      return () => unsubscribe();
    }
  }, [publicClient, address, refetch]);

  return (
    <div className='header'>
      <img className='logo' src={Logo} alt='logo' onClick={() => store.navigate('/')} />
      <img className='logo logo-dark' src={LogoDark} alt='logo' onClick={() => store.navigate('/')} />
      <div className="right-side">
        <ConnectButton showBalance={true} />
        {isConnected && !isWrongNetwork && (
          <Link className="address-value" to={`/member/${address}`}>
            <HexAvatar address={address} width={35} height={35} />
          </Link>
          // <Button onClick={profileStore.openModal} className="btn-profile">
          //   <img src={Action} alt='player' width={20} />
          //   @name
          // </Button>
        )}
      </div>
    </div>
  );
};
