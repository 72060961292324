import useNotifications from "hooks/useNotifications";
import { createContext, ReactElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "stores/RootStore";
import { useWriteContract } from "wagmi";


interface IStoreProvider {
  children: ReactNode;
}
const StoreContext = createContext({});
export function StoreProvider({ children }: IStoreProvider): ReactElement {
  const navigate = useNavigate();
  store.initNavigate(navigate);
  const notify = useNotifications();
  store.initNotify(notify);

  const writeContract = useWriteContract();
  store.writeContract = writeContract;

  return <StoreContext.Provider value={{}}>{children}</StoreContext.Provider>
}