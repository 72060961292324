import { readContract } from "@wagmi/core";
import { config, gomokuContract } from "App/config";
import { isBigInt } from "helpers";
import { action, makeObservable, observable } from "mobx";
import { abi } from "web3/contract/abi";



export class BlockTimestampStore {
  @observable blockTimestamp: bigint | undefined;
  @observable intervalId: number | undefined;
  constructor() {
    makeObservable(this);
    this.startFetchingBlockTimestamp(1000);
  }

  @action.bound
  setBlockTimesamp(value: bigint) {
    this.blockTimestamp = value;
  }

  @action.bound
  async getBlockTimestamp() {
    const result = await readContract(config, {
      abi,
      address: gomokuContract,
      functionName: 'getCurrentBlockTimestamp',
      args: [],
    });
    if (isBigInt(result)) { 
      this.setBlockTimesamp(result as bigint);
    }
  }

  @action.bound
  startFetchingBlockTimestamp(intervalMs: number = 1000) {
    // Clear any existing interval to avoid multiple instances
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    // Set interval to fetch block timestamp periodically
    this.intervalId = setInterval(async () => {
      await this.getBlockTimestamp();
    }, intervalMs);
  }

  @action.bound
  stopFetchingBlockTimestamp() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }


}