import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import '../../pages/Games/style.scss';

type EmptyDialogProps = {
  open: boolean;
  onClose: () => void;
};

const EmptyDialog = (props: EmptyDialogProps) => {
  const { open, onClose } = props;

  return (
    <Dialog
      className="dialog-modal"
      open={open}
      onClose={onClose}
      aria-labelledby="empty-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="empty-dialog-title">How to play?</DialogTitle>
      <DialogContent>
        <div className="video-container">
          <iframe
            width="100%"
            height="400px"
            src="https://www.youtube.com/embed/dQw4w9WgXcQ"
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmptyDialog;
