import { action, computed, makeObservable, observable } from "mobx";
import { GameRaw } from "types";
import { RootStore } from "./RootStore";


export class GameFinishedModalStore {
  @observable isOpen = false;
  @observable content = '';
  @observable buttonName = '';
  @observable type: 'win' | 'loose' | 'draw' | 'quit' | undefined;
  @observable onClaim: (() => void) | undefined;
  constructor(private appState: RootStore) {
    makeObservable(this);
  }

  @action.bound
  onClose() {
    this.content = '';
    this.buttonName = '';
    this.onClaim = undefined;
    this.isOpen = false;
  }

  @action.bound
  openModal() {
    if (!this.appState.gameStore.iAmGameParticipant) {
      return;
    }
    // Do not open modal if the user is not connected
    if (!this.appState.isConnected) {
      return;
    }
    if (this.appState.gameStore.currentGame?.opponentRunOutOfTime) {
      this.isOpen = true;
      return this.openTimeoutWinModal();
    }
    if (this.appState.gameStore.currentGame?.winnerIsMe && this.appState.gameStore.currentGame?.playersHaveNotRunOutOfTimes) {
      this.isOpen = true;
      return this.openWinModal();
    }
    if (this.appState.gameStore.currentGame?.isDraft) {
      this.isOpen = true;
      return this.openDrawModal();
    }
    if (this.appState.gameStore.currentGame?.looserIsMe && !this.appState.gameStore.currentGame?.meRunOutOfTime) {
      this.isOpen = true;
      return this.openLoseModal();
    }
    if (this.appState.gameStore.currentGame?.looserIsMe && this.appState.gameStore.currentGame?.meRunOutOfTime) {
      this.isOpen = true;
      return this.openLoseModal('Time’s up, you lose!');
    }
  }

  @action.bound
  openTimeoutWinModal() {
    this.type = 'win';
    this.content = 'Congratulations, you win!';   
    if (this.appState?.gameStore?.currentGame?.hasBet && !this.appState?.gameStore?.currentGame.winningsWasClaimed) {
      this.onClaim = this.appState.gameStore.currentGame.checkAndEndGameWithWin;
      this.buttonName = "Claim";
    }
  }

  @action.bound
  openWinModal(text?: string) {
    this.type = 'win';
    this.content = text || 'Congratulations, you win!';   
    if (this.appState?.gameStore?.currentGame?.hasBet && !this.appState?.gameStore?.currentGame.winningsWasClaimed) {
      this.onClaim = this.appState.gameStore.currentGame.claimBets;
      this.buttonName = "Claim";
    } else {
      this.onClaim = undefined;
      this.buttonName = "";
    }
  }

  @action.bound
  openDrawModal() {
    this.type = 'draw';
    this.content = 'Game Is TIE';   
    if (this.appState.gameStore?.currentGame?.claimBetAvailable) {
      this.onClaim = this.appState.gameStore.currentGame.claimOwnBet;
      this.buttonName = "Claim";
    }
  }

  @action.bound
  openLoseModal(text?: string) {
    this.type = 'loose';
    this.content = text || "Oops, you lose";
  }

}

export class GameStore {
  @observable currentGameId: string | null = null;
  @observable _currentGame: GameRaw | null = null;
  @observable _isLoading = true;
  gameFinishedModal: GameFinishedModalStore; 
  constructor(private appStore: RootStore) {
    makeObservable(this);
    this.gameFinishedModal = new GameFinishedModalStore(appStore);
  }

  @computed
  get currentGame() {
    return this.appStore.gamesStore.gamesList.find(game => game.id === this.currentGameId);
  }

  @computed
  get iAmGameParticipant() {
    return [this.currentGame?.player1, this.currentGame?.player2].includes(this.appStore.myAddress);
  }

  @computed
  get player1Rating() {
    if (!this.currentGame?.player1) {
      return null;
    }
    return this.appStore.leaderboardStore.getPlayerScore(this.currentGame.player1)
  }

  @computed
  get player2Rating() {
    if (!this.currentGame?.player2) {
      return null;
    }
    return this.appStore.leaderboardStore.getPlayerScore(this.currentGame.player2)
  }

  @computed
  get lastStep() {
    return this.currentGame?.step;
  }

  @computed
  private get player1LastMoveBigInt() {
    return BigInt.call(this, (0));
  }

  @computed
  private get player2LastMoveBigInt() {
    return BigInt.call(this, (0));
  }

  @computed
  get player1LastMoveTimestamp() {
    return Number(this.player1LastMoveBigInt.toString());
  }

  @computed
  get player2LastMoveTimestamp() {
    return Number(this.player2LastMoveBigInt.toString());
  }

  @computed
  get isLoading() {
    return this._isLoading;
  }

  @computed
  get turn() {
    // const currentPlayerKey = `player${this.game?.currentPlayer}`;
    // //@ts-ignores
    // const currentPlayerAddress = this.game?.currentPlayerKey as string;
    // if (this.appStore.myAddress === currentPlayerAddress) {
    //   return
    // }
    return this.currentGame?.currentPlayer;
  }

  @computed
  get isMyTurn() {
    const currentPlayerKey = `player${this.currentGame?.currentPlayer}`;
    //@ts-ignore
    const currentPlayerAddress = this.game?.[currentPlayerKey] as string;
    return this.appStore.myAddress === currentPlayerAddress;
  }

  set isLoading(val: boolean) {
    this._isLoading = val;
  }

}