import { Radio, RadioProps } from "@material-ui/core";
import React from "react";

const CustomRadio: React.FC<RadioProps> = (props) => {
  return (
    <Radio
      {...props}
      icon={<svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.3">
<path d="M17.7151 0.356412C15.9763 -0.548027 14.9031 0.356425 14.1288 2.08308C13.2051 4.13862 12.2678 6.26269 11.8059 7.56453C10.6512 10.7027 9.61882 13.2789 8.49132 16.5267C6.96987 15.1563 3.30209 10.9493 1.52254 13.6763C0.802574 14.8411 -0.691702 17.0748 0.367877 18.4452C1.97083 20.5145 5.70652 22.2137 8.08378 23.0496C9.29279 23.4881 9.95842 23.4059 11.181 21.7889C12.2641 20.1888 13.2486 18.5229 14.1288 16.8008C16.0714 13.32 17.8509 9.79822 19.6984 6.26269C20.2726 5.43615 20.6831 4.50554 20.9074 3.52197C21.0025 1.91864 18.8697 0.945668 17.7151 0.356412Z" fill="url(#paint0_radial_608_1852)"/>
<path d="M17.7151 0.356412C15.9763 -0.548027 14.9031 0.356425 14.1288 2.08308C13.2051 4.13862 12.2678 6.26269 11.8059 7.56453C10.6512 10.7027 9.61882 13.2789 8.49132 16.5267C6.96987 15.1563 3.30209 10.9493 1.52254 13.6763C0.802574 14.8411 -0.691702 17.0748 0.367877 18.4452C1.97083 20.5145 5.70652 22.2137 8.08378 23.0496C9.29279 23.4881 9.95842 23.4059 11.181 21.7889C12.2641 20.1888 13.2486 18.5229 14.1288 16.8008C16.0714 13.32 17.8509 9.79822 19.6984 6.26269C20.2726 5.43615 20.6831 4.50554 20.9074 3.52197C21.0025 1.91864 18.8697 0.945668 17.7151 0.356412Z" fill="#C0A5A5" fill-opacity="0.2"/>
<g  opacity="0.8">
<g  opacity="0.8">
<path d="M8.53232 16.9928C8.53232 16.8969 8.45081 16.9928 8.53232 16.9928V16.9928Z" fill="#215700"/>
</g>
<g  opacity="0.8">
<path d="M17.7151 0.356412C15.9763 -0.548027 14.9031 0.356425 14.1288 2.08308C13.2051 4.13862 12.2678 6.26269 11.8059 7.56453C10.6512 10.7027 9.61882 13.2789 8.49132 16.5267C6.96987 15.1563 3.30209 10.9493 1.52254 13.6763C0.802574 14.8411 -0.691702 17.0748 0.367877 18.4452C1.97083 20.5145 5.70652 22.2137 8.08378 23.0496C9.29279 23.4881 9.95842 23.4059 11.181 21.7889C12.2641 20.1888 13.2486 18.5229 14.1288 16.8008C16.0714 13.32 17.8509 9.79822 19.6984 6.26269C20.2726 5.43615 20.6831 4.50554 20.9074 3.52197C21.0025 1.91864 18.8697 0.945668 17.7151 0.356412ZM19.9972 3.75492C19.6298 4.73534 19.1752 5.68018 18.6388 6.57787C16.9951 9.90785 15.4057 13.2652 13.6941 16.5678C12.8247 18.2396 11.7651 20.0074 10.6784 21.7204C9.59164 23.4333 8.50489 22.7755 6.95628 21.8848C5.40766 20.9941 1.52255 19.4867 0.856914 17.7737C0.462968 16.7048 1.72631 14.4986 2.39194 13.6626C3.22059 12.4704 7.58116 16.5267 8.53207 17.1982C9.8905 15.0604 14.156 0.178265 16.6827 1.04159C17.5792 1.20604 20.3912 2.49419 19.9972 3.75492V3.75492Z" fill="black"/>
</g>
</g>
</g>
<defs>
<radialGradient id="paint0_radial_608_1852" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.94009 6.45837) scale(15.5861 15.8787)">
<stop stopColor="#2A333D"/>
<stop offset="0.68" stopColor="#2A333D"/>
<stop offset="1" stopColor="#2A333D"/>
</radialGradient>
</defs>
</svg>
}
      checkedIcon={<svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_608_1835)">
            <g  opacity="0.6">
            <path d="M22.9571 6.71161C22.8647 6.27595 22.7234 5.85209 22.536 5.4481C21.8692 3.89207 20.8286 2.52473 19.5067 1.46741C17.7679 0.584318 16.654 3.00262 15.8796 4.72804C14.9559 6.76594 14.0186 8.80384 13.5431 10.1624C12.5107 12.9747 11.5598 15.3523 10.5546 18.1646C9.53538 17.1824 8.39937 16.3291 7.17208 15.624C5.99025 14.9991 2.78433 12.6079 2.2953 13.0155C1.64911 13.8274 1.0634 14.6856 0.542916 15.5833C0.194292 16.2059 0.0112305 16.9076 0.0112305 17.6212C0.0112305 18.3348 0.194292 19.0364 0.542916 19.6591C1.41231 21.8192 7.48452 24.713 9.87537 25.5554C10.2321 25.705 10.6161 25.779 11.0029 25.7728C11.3792 25.7919 11.7561 25.746 12.1168 25.6369C13.353 25.3108 13.6518 24.944 14.6299 23.4224C15.608 21.9008 19.5338 14.5643 20.9602 11.983C22.3865 9.40164 23.2016 7.70338 22.9571 6.71161Z" fill="#202830"/>
            </g>
            <path d="M9.32207 15.9633C8.16068 14.791 6.84982 13.7769 5.42337 12.9472C4.06494 12.2136 2.33973 12.4581 2.12238 13.0152C1.90503 13.5722 8.58852 17.4034 8.58852 17.4034L9.32207 15.9633Z" fill="white"/>
            <path d="M7.59473 22.4316C8.70864 22.9886 9.04825 23.2468 10.3116 22.9071C11.5749 22.5675 11.833 22.2142 12.8111 20.6926C13.7892 19.171 17.7286 11.8345 19.1414 9.25317C20.5542 6.67183 21.4372 5.04151 21.1791 4.04973C20.921 3.05795 20.7036 2.37865 20.2282 2.31072C19.7527 2.24279 7.59473 22.4316 7.59473 22.4316Z" fill="#AF139E"/>
            <g filter="url(#filter0_d_608_1835)">
            <path d="M17.7151 0.353353C15.9763 -0.543324 14.9031 0.353367 14.1288 2.0652C13.2051 4.10311 12.2678 6.20894 11.8059 7.49961C10.6512 10.6108 9.61882 13.165 8.49132 16.3849C6.96987 15.0263 3.30209 10.8553 1.52254 13.559C0.802574 14.7138 -0.691702 16.9283 0.367877 18.2869C1.97083 20.3384 5.70652 22.0231 8.08378 22.8518C9.29279 23.2866 9.95842 23.2051 11.181 21.6019C12.2641 20.0155 13.2486 18.3639 14.1288 16.6566C16.0714 13.2057 17.8509 9.71414 19.6984 6.20894C20.2726 5.3895 20.6831 4.46687 20.9074 3.49174C21.0025 1.90218 18.8697 0.937552 17.7151 0.353353Z" fill="url(#paint0_linear_608_1835)"/>
            </g>
            <g  opacity="0.8">
            <g  opacity="0.8">
            <path d="M8.53232 16.847C8.53232 16.7518 8.45081 16.847 8.53232 16.847Z" fill="#215700"/>
            </g>
            <g  opacity="0.8">
            <path d="M17.7151 0.353353C15.9763 -0.543324 14.9031 0.353367 14.1288 2.0652C13.2051 4.10311 12.2678 6.20894 11.8059 7.49961C10.6512 10.6108 9.61882 13.165 8.49132 16.3849C6.96987 15.0263 3.30209 10.8553 1.52254 13.559C0.802574 14.7138 -0.691702 16.9283 0.367877 18.2869C1.97083 20.3384 5.70652 22.0231 8.08378 22.8518C9.29279 23.2866 9.95842 23.2051 11.181 21.6019C12.2641 20.0155 13.2486 18.3639 14.1288 16.6566C16.0714 13.2057 17.8509 9.71414 19.6984 6.20894C20.2726 5.3895 20.6831 4.46687 20.9074 3.49174C21.0025 1.90218 18.8697 0.937552 17.7151 0.353353ZM19.9972 3.7227C19.6298 4.6947 19.1752 5.63143 18.6388 6.52142C16.9951 9.82282 15.4057 13.1514 13.6941 16.4256C12.8247 18.0831 11.7651 19.8357 10.6784 21.534C9.59164 23.2322 8.50489 22.5801 6.95628 21.697C5.40766 20.8139 1.52255 19.3194 0.856914 17.6212C0.462968 16.5615 1.72631 14.3741 2.39194 13.5454C3.22059 12.3634 7.58116 16.3849 8.53207 17.0506C9.8905 14.9312 14.156 0.176735 16.6827 1.03265C17.5792 1.19569 20.3912 2.47279 19.9972 3.7227Z" fill="#AF139E"/>
            </g>
            </g>
            <path  d="M14.3861 7.07936C14.3861 6.61743 14.0601 6.10116 13.5711 6.14192C13.082 6.18268 12.6202 7.03861 13.1092 7.37826C13.3945 7.58205 13.5711 7.86735 13.9378 7.73149C14.0725 7.68506 14.1887 7.59666 14.2694 7.47928C14.3501 7.3619 14.391 7.22173 14.3861 7.07936Z" fill="url(#paint1_linear_608_1835)"/>
            <path  d="M7.97601 16.5891C6.49721 15.3139 4.8687 14.2235 3.1264 13.342C2.35209 13.1382 2.09399 14.0213 1.4691 15.0538C1.03441 15.8011 0.939314 17.1733 1.80871 16.3445C2.67811 15.5158 3.60185 15.6516 5.10971 16.1951C6.61758 16.7385 9.08993 17.5673 7.97601 16.5891Z" fill="url(#paint2_linear_608_1835)"/>
            <path d="M1.90314 18.6541C1.90314 18.6541 7.33688 21.2627 8.0161 21.5072C8.69531 21.7518 9.61905 21.8061 10.733 20.4203C11.8469 19.0345 16.9003 9.55152 18.0142 7.22831C19.1281 4.90511 19.033 5.70667 18.3538 7.43209C17.6746 9.15752 11.8605 20.3252 11.1269 21.2627C10.3934 22.2001 10.1081 23.4772 7.29613 22.2001C4.48417 20.923 0.802811 18.5047 1.90314 18.6541Z" fill="#D9D9D9"/>
            </g>
            <defs>
            <filter id="filter0_d_608_1835" x="0" y="0" width="20.9106" height="25.0978" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2.0197"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.686275 0 0 0 0 0.0745098 0 0 0 0 0.619608 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_608_1835"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_608_1835" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_608_1835" x1="8.07014" y1="4.76604" x2="5.61448" y2="19.8125" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="#B39CB3"/>
            </linearGradient>
            <linearGradient id="paint1_linear_608_1835" x1="122.735" y1="296.952" x2="125.993" y2="298.575" gradientUnits="userSpaceOnUse">
            <stop offset="0.03" stopColor="white"/>
            <stop offset="0.19" stopColor="#92FA8B"/>
            <stop offset="0.39" stopColor="#4CA453"/>
            <stop offset="0.95" stopColor="#3D7E00"/>
            </linearGradient>
            <linearGradient id="paint2_linear_608_1835" x1="479.947" y1="698.503" x2="481.12" y2="708.799" gradientUnits="userSpaceOnUse">
            <stop offset="0.03" stopColor="white"/>
            <stop offset="0.19" stopColor="#92FA8B"/>
            <stop offset="0.39" stopColor="#4CA453"/>
            <stop offset="0.95" stopColor="#3D7E00"/>
            </linearGradient>
            <clipPath id="clip0_608_1835">
            <rect width="23" height="26" fill="white"/>
            </clipPath>
            </defs>
            </svg>

            }
    />
  );
};

export default CustomRadio;
