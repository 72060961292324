import { observer } from 'mobx-react-lite';
import '../../pages/Games/style.scss';
import { store } from '../../stores/RootStore';

export const Theme = observer(() => {
  return (
    <div className='theme'>
      <button className="button" onClick={store.themeStore.toggleTheme}>
        <span className="icon">{store.themeStore.themeId === 'dark' ? '🌙' : '☀️'}</span>
        <div className={`toggle-switch ${store.themeStore.themeId === 'dark' ? 'toggled' : ''}`}>
          <div className="toggle-knob"></div>
        </div>
      </button>
    </div>
  );
});
