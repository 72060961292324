import { Button, Dialog, DialogActions, Typography } from "@material-ui/core";

import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import './style.scss';
import { StyledDialogContent } from "./styled";

const GameEndDialog = observer(() => {
  const { gameStore } = useStore();
  const { gameFinishedModal } = gameStore;
  const { type, isOpen, content, buttonName, onClaim, onClose } = gameFinishedModal;

  const handleClose = () => {
    const { currentGame } = gameStore;
    if (currentGame) {
      currentGame.modalDismissed = true;
    }
    onClose();
  };
  return (
    content ? <Dialog
      itemType={type}
      open={isOpen}
      keepMounted={true}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
      className="dialog-modal-finished"
    >
      <StyledDialogContent>
        <Typography variant="h2" component="h2" aria-label={content}>
          {content}
        </Typography>
      </StyledDialogContent>
      <DialogActions className="actions">
        {onClaim && buttonName ? (
          <Button onClick={onClaim} size="large" color="primary" className="claim-btn">
            {buttonName}
          </Button>
        ) : null}
        <Button onClick={handleClose} size="large" color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog> : null
  );
});

export default GameEndDialog;
