import { isAddress } from 'ethers';

function isValidAddress(address: string): boolean {
  return isAddress(address);
}

const shortenAddress = (address: string, count?: number) => {
  const offset = count ? count : 5;
  const leftOffset = offset + 2;
  const rightOffset = offset;
  return `${address.slice(0, leftOffset)}...${address.slice(-rightOffset)}`;
};

export {
  isValidAddress,
  shortenAddress
};

