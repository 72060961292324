import { computed, makeObservable, observable } from "mobx";
import React from "react";
import Win from '../assets/imgs/win.png';
import { RootStore } from "./RootStore";


export class MarqueeStore {
  @observable speed = 10;
  @observable pauseOnHover = true;

  constructor(private appStore: RootStore) {
    makeObservable(this);
  }

  @computed 
  get textString() {
    return this.top5PlayersData;
  }

  @computed
  get top5Players() {
    return this.appStore.leaderboardStore.playersSortedStats.slice(0, 5);
  }

  @computed
  get top5PlayersData() {
    return (
      React.createElement(
        'div',
        {className: 'marquee-top-rating-players'},
        React.createElement('span', {className: 'marquee-top-rating-players-item-title'}, 
          React.createElement('img', { src: Win, alt: 'Win Icon', className: 'win-icon' }),
          'Top 5 players:'
        ),
        
        this.top5Players.map(([address, scores], index) => (
          React.createElement(
            'div',
            { key: address, className: 'marquee-top-rating-players-item' },
            React.createElement('span', { className: 'marquee-top-rating-players-item-index' }, `${index + 1}`),
            React.createElement('span', { className: 'marquee-top-rating-players-item-address' }, this.appStore.profileStore.nicknamesMap[address] || address),
            // React.createElement('span', { className: 'marquee-top-rating-players-item-wins' }, `${scores.score}`)
          )
        ))
      )
    );
  }
}