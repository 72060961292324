import { action, computed, makeObservable, observable } from "mobx";

interface Theme {
  id: string;
  textColorPrimary: string;
  background: string;
  tableBackground: string;
  backgoundImage?: string;
}

const themes = [
  {
    id: 'classic',
    textColorPrimary: '#864E15',
    backgoundImage: 'url(/static/media/wood-bg.b15090dc2e899c48158d.jpg)',
    background: '',
    tableBackground: 'rgba(255, 255, 255, 0.5) !important',
  },
  {
    id: 'dark',
    textColorPrimary: '#fff',
    background: 'linear-gradient(180deg, rgba(35, 41, 49, 0.2) 0%, rgba(65, 16, 68, 0.5) 100%), #151b23',
    tableBackground: '#212830 !important',
  }
] as const;
type ThemeId = typeof themes[number]["id"];

export class ThemeStore {
  @observable themeId: ThemeId = 'classic';

  constructor() {
    makeObservable(this);
    const savedTheme = localStorage.getItem('theme') as ThemeId;
    if (savedTheme) {
      this.setTheme(savedTheme);
    } else {
      this.applyTheme(this.themeId);
    }
  }

  @computed
  get theme() {
    return themes.find(theme => theme.id === this.themeId) || themes[0];
  }
  

  @action
  setTheme(themeId: ThemeId) {
    this.themeId = themeId;
    localStorage.setItem('theme', themeId);
    this.applyTheme(themeId);
  }

  @action.bound
  toggleTheme() {
    const newThemeId = this.themeId === 'dark' ? 'classic' : 'dark';
    this.setTheme(newThemeId);
  }

  private applyTheme(themeId: ThemeId) {
    if (themeId === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }
}