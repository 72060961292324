import { memo, ReactNode } from 'react';
import { icons } from './icons';

export type IconName = keyof ReturnType<typeof icons>;
type Color = string;

export type SvgIconProps = {
  secondaryColor?: Color;
  name: IconName;
  height?: number;
  width?: number;
  background?: string;
  classNames?: string;
  stroke?: string;
} & (
    | { height: number; width: number; size?: number }
    | { height?: number; width?: number; size?: number, fill?: string, stroke?:string }
  );

export const SvgIcon = memo(
  (props: SvgIconProps) => {
    const { name } = props;
    if (icons(props) && icons(props)) {

    }
    //@ts-ignore
    const icon = icons(props)[name] as ReactNode;
    return icon;
  },
);