import { Button, ButtonProps, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
    disabledButton: {
        opacity: 0.7,
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: '#FFB672'
    },
    hiddenText: {
        visibility: 'hidden',
    }
}));

interface CustomButtonProps extends ButtonProps {
    loading?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({ loading, children, onClick, disabled, ...props }) => {
    const classes = useStyles();
    const onClickCover = (event: any) => {
      event?.stopPropagation();
      onClick && onClick(event);
    }
    return (
        <Button
            className={disabled ? classes.disabledButton : ''}
            disabled={disabled || loading}
            onClick={onClickCover}
            {...props}
        >
            <span className={loading ? classes.hiddenText : ''}>
                {children}
            </span>
            {loading && <CircularProgress size={24} className={classes.loader} />}
        </Button>
    );
};

export default CustomButton;
