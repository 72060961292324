import backgroundImage from "assets/imgs/wood-bg.jpg";
import styled from "styled-components";

export const AppContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-image: url(${backgroundImage});
  gap: 40px;
  min-height: 100vh;
  padding: 10px;
  padding-bottom: 80px;
`;
