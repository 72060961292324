import React, { useState } from 'react';
import '../../pages/Games/style.scss';
import EmptyDialog from './EmptyDialog';

export const Hiw = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div className='how-to-play'>
      <button className="button" onClick={handleOpen}>
        <span className="icon">
          ?
        </span>
      </button>
      <EmptyDialog open={isModalOpen} onClose={handleClose} />
    </div>
  );
};
