import { computed, makeObservable, observable } from "mobx";
import { Member } from "models/Member";
import { emptyAddress } from "types";
import { RootStore } from "./RootStore";


export class MembersStore {

  @observable currentMemberAddress: string | undefined = undefined;
  
  constructor(private appStore: RootStore) {
    makeObservable(this);
  }

  getMember(address: string | undefined) {
    return this.getMemberByAddress(address);
  }

  getMemberByAddress(address: string | undefined): Member | undefined {
    return this.members.find(memnber => memnber.address === address);
  }

  @computed
  get members() {
    const games = this.appStore.gamesStore.allGamesList;
    const addresses = games.reduce((acc, curr) => {
      if (!acc.includes(curr.player1) && curr.player1 !== emptyAddress) {
        acc.push(curr.player1);
      }
      if (!acc.includes(curr.player2) && curr.player2 !== emptyAddress) {
        acc.push(curr.player2);
      }
      return acc;
    }, [] as string[]);
    return addresses.map(address => {
      return new Member(this.appStore, address);
    })
  }

  @computed
  get memberProfile() {
    return this.members.find(member => member.address === this.currentMemberAddress)
  }
  
}