import { Tab, Tabs } from '@material-ui/core';
import { velas } from 'App/config';
import CustomButton from 'components/CustomButton';
import { GamesTable } from 'components/GamesTable';
import { Leaderboard } from 'components/Leaderboard';
import { Loader } from 'components/Loader';
import { LoaderLight } from 'components/LoaderLight';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { GamesTableModel } from 'models/GamesTableModel';
import { SearchModel } from 'models/SearchModel';
import { NewGameDialog } from 'pages/Game/components/NewGameDialog';
import React, { useEffect, useRef, useState } from 'react';
import { useAccount } from 'wagmi';
import './style.scss';

interface GamesListProps {
  searchModel: SearchModel;
}

const AllGamesList: React.FC<GamesListProps> = observer(({ searchModel }) => {
  const { gamesStore } = useStore();
  const gamesTableModel = useRef(new GamesTableModel(gamesStore.gamesList)).current;

  useEffect(() => {
    gamesTableModel.setGames(gamesStore.gamesList);
  }, [gamesStore.gamesList, gamesTableModel]);

  return <GamesTable gamesModel={gamesTableModel} searchModel={searchModel} />;
});

const MyGamesList: React.FC<GamesListProps> = observer(({ searchModel }) => {
  const { gamesStore } = useStore();
  const gamesTableModel = useRef(new GamesTableModel(gamesStore.myGamesList)).current;

  useEffect(() => {
    gamesTableModel.setGames(gamesStore.myGamesList);
  }, [gamesStore.myGamesList, gamesTableModel]);

  return <GamesTable gamesModel={gamesTableModel} searchModel={searchModel} />;
});

export const Games = observer(() => {
  const [isFocused, setIsFocused] = useState(false);
  const store = useStore();
  const { gamesStore, profileStore, gameStore } = store;
  const [newGameDialogOpen, setNewGameDialogOpen] = useState(false);
  const { isConnected, address, chain } = useAccount();
  const [activeTab, setActiveTab] = useState(0);
  const searchModel = useRef(new SearchModel()).current;

  useEffect(() => { profileStore.myAddress = address; }, [address]);

  useEffect(() => { 
    if (!gamesStore.fetchInterval) {
      gamesStore.startPolling();
    }
    return () => {
      gamesStore.stopPolling();
    }
  }, []);

  const isWrongNetwork = chain?.id !== velas.id;

  const onNewGameDlgClosed = () => {
    setNewGameDialogOpen(false);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };
  if (gamesStore.isLoading) return <Loader show={true} text="Loading games..." />;
  // if (error) return <div>Error loading games: {error.message}</div>;

  const totalRooms = gamesStore.gamesList.length;


  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => {
    setIsFocused(false);
    searchModel.setSearchQuery('');
  };

  return (
    <div className='main-container'>
      <div className='body'>
        <div className='actions'>
          {isConnected && !isWrongNetwork && (
            <div className="group-btns">
              <CustomButton onClick={() => setNewGameDialogOpen(true)}>Create Game</CustomButton>
              <div className="input-wrapper-search">
                <button
                  className={`icon-search ${isFocused ? 'active' : ''}`}
                  onClick={handleFocus}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 48 48"><defs><mask id="ipTSearch0"><g fill="none" stroke="#edcdad" strokeLinejoin="round" strokeWidth={6}><path fill="#555" d="M21 38c9.389 0 17-7.611 17-17S30.389 4 21 4S4 11.611 4 21s7.611 17 17 17Z"></path><path strokeLinecap="round" d="M26.657 14.343A7.98 7.98 0 0 0 21 12a7.98 7.98 0 0 0-5.657 2.343m17.879 18.879l8.485 8.485"></path></g></mask></defs><path fill="#fff" d="M0 0h48v48H0z" mask="url(#ipTSearch0)"></path></svg>
                </button>
                <input
                  placeholder="Search.."
                  className="input-search"
                  name="text"
                  type="text"
                  value={searchModel.searchQuery}
                  onChange={(e) => searchModel.setSearchQuery(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}

          <p className='total-rooms-label'>Total Rooms: {totalRooms}</p>
        </div>

        <Tabs className='tabs' value={activeTab} onChange={handleTabChange}>
          <Tab label="Rooms" />
          <Tab label="My Rooms" />
          <Tab label="Leaderboard" />
        </Tabs>

        {activeTab === 0 && (<AllGamesList searchModel={searchModel} />)}
        {activeTab === 1 && (<MyGamesList searchModel={searchModel} />)}
        {activeTab === 2 && (<Leaderboard searchModel={searchModel} />)}
      </div>
      <NewGameDialog
        open={newGameDialogOpen}
        onClose={onNewGameDlgClosed}
      />
      <LoaderLight show={profileStore.isLoading} />
    </div>
  );
});