import styled from "styled-components";

export const FormRow = styled.div`
  margin-top: 3rem;
`;

export const FormCol = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .game-type-formrow {
    padding: 25px 10px 15px !important;
    width: auto !important;
    height: max-content;
  }
`;