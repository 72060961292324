export const abi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_gomokuContract",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_initialCommissionRate",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "player",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "BetClaimed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "player",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "BetPlaced",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "newRate",
				"type": "uint256"
			}
		],
		"name": "CommissionRateUpdated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "CommissionWithdrawn",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "GameCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "GameCancelled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "winner",
				"type": "address"
			}
		],
		"name": "GameWon",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "player",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "nickname",
				"type": "string"
			}
		],
		"name": "NicknameSet",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "winner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "RewardClaimed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "winner",
				"type": "address"
			}
		],
		"name": "RewardClaimed",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "cancelGame",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "checkAndEndGameIfTimeout",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "player",
				"type": "address"
			}
		],
		"name": "claimBet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "claimReward",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "commissionRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "betAmount",
				"type": "uint256"
			},
			{
				"internalType": "enum Gomoku.GameTypes",
				"name": "gameType",
				"type": "uint8"
			},
			{
				"internalType": "uint8",
				"name": "gameLength",
				"type": "uint8"
			},
			{
				"internalType": "address",
				"name": "expectedPlayer2",
				"type": "address"
			}
		],
		"name": "createGame",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "betAmount",
				"type": "uint256"
			},
			{
				"internalType": "enum Gomoku.GameTypes",
				"name": "gameType",
				"type": "uint8"
			},
			{
				"internalType": "uint8",
				"name": "gameLength",
				"type": "uint8"
			}
		],
		"name": "createGameSimple",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getAllNicknames",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "playerAddress",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "nickname",
						"type": "string"
					}
				],
				"internalType": "struct GomokuMain.PlayerNickname[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getCurrentBlockTimestamp",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "getGameById",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "id",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "expectedPlayer2",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player1",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player2",
								"type": "address"
							}
						],
						"internalType": "struct Gomoku.Players",
						"name": "players",
						"type": "tuple"
					},
					{
						"internalType": "enum Gomoku.Player[30][30]",
						"name": "board",
						"type": "uint8[30][30]"
					},
					{
						"internalType": "uint8",
						"name": "gameLength",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.Player",
						"name": "currentPlayer",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.GameStatus",
						"name": "status",
						"type": "uint8"
					},
					{
						"internalType": "address",
						"name": "winner",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "betAmount",
						"type": "uint256"
					},
					{
						"internalType": "enum Gomoku.GameTypes",
						"name": "gameType",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "gameStartedAt",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "bool",
								"name": "rewardClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player1BetClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player2BetClaimed",
								"type": "bool"
							}
						],
						"internalType": "struct Gomoku.BetClaims",
						"name": "claims",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "uint8",
								"name": "row",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "column",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.LastPlayedCell",
						"name": "lastPlayedCell",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "playerAddress",
								"type": "address"
							},
							{
								"internalType": "uint256",
								"name": "prevMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "currentMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint8",
								"name": "x",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "y",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.Move[]",
						"name": "movesArray",
						"type": "tuple[]"
					}
				],
				"internalType": "struct Gomoku.GameDetails",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "player",
				"type": "address"
			}
		],
		"name": "getNickname",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gomokuContract",
		"outputs": [
			{
				"internalType": "contract Gomoku",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "isRewardClaimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			}
		],
		"name": "joinGame",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "listAllGames",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "id",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "expectedPlayer2",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player1",
								"type": "address"
							},
							{
								"internalType": "address",
								"name": "player2",
								"type": "address"
							}
						],
						"internalType": "struct Gomoku.Players",
						"name": "players",
						"type": "tuple"
					},
					{
						"internalType": "enum Gomoku.Player[30][30]",
						"name": "board",
						"type": "uint8[30][30]"
					},
					{
						"internalType": "uint8",
						"name": "gameLength",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.Player",
						"name": "currentPlayer",
						"type": "uint8"
					},
					{
						"internalType": "enum Gomoku.GameStatus",
						"name": "status",
						"type": "uint8"
					},
					{
						"internalType": "address",
						"name": "winner",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "betAmount",
						"type": "uint256"
					},
					{
						"internalType": "enum Gomoku.GameTypes",
						"name": "gameType",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "gameStartedAt",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "bool",
								"name": "rewardClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player1BetClaimed",
								"type": "bool"
							},
							{
								"internalType": "bool",
								"name": "player2BetClaimed",
								"type": "bool"
							}
						],
						"internalType": "struct Gomoku.BetClaims",
						"name": "claims",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "uint8",
								"name": "row",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "column",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.LastPlayedCell",
						"name": "lastPlayedCell",
						"type": "tuple"
					},
					{
						"components": [
							{
								"internalType": "address",
								"name": "playerAddress",
								"type": "address"
							},
							{
								"internalType": "uint256",
								"name": "prevMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "currentMoveTs",
								"type": "uint256"
							},
							{
								"internalType": "uint8",
								"name": "x",
								"type": "uint8"
							},
							{
								"internalType": "uint8",
								"name": "y",
								"type": "uint8"
							}
						],
						"internalType": "struct Gomoku.Move[]",
						"name": "movesArray",
						"type": "tuple[]"
					}
				],
				"internalType": "struct Gomoku.GameDetails[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "gameId",
				"type": "uint256"
			},
			{
				"internalType": "uint8",
				"name": "x",
				"type": "uint8"
			},
			{
				"internalType": "uint8",
				"name": "y",
				"type": "uint8"
			}
		],
		"name": "makeMove",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "nicknames",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "rewardsClaimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newRate",
				"type": "uint256"
			}
		],
		"name": "setCommissionRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_gameContractAddress",
				"type": "address"
			}
		],
		"name": "setGameContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "nickname",
				"type": "string"
			}
		],
		"name": "setNickname",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "totalBets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalCommissionCollected",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawCommissions",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]